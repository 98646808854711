class QuestionnaireService {
  getValidators = (translate) => ({
    Email: (email) =>
      !email || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
        ? ''
        : translate('page.serviceSupplier.invalidEmail'),
  });
}

export default new QuestionnaireService();
