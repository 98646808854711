import React, { PureComponent } from 'react';
import { message } from 'antd';

import Questionnaire from './Questionnaire';
import FormStateContainer from '../../../components/FormStateContainer';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, DownloadReceiptPDFService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import {
  DATE_FIELDS,
  LABEL_TRANSLATIONS,
  REQUIRED_FIELDS,
  RISC_LEVEL_FIELDS,
  STATIC_SELECT_LIST_BY_FIELD_NAME,
} from './constants';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class QuestionnaireContainer extends PureComponent {
  state = {
    isLoading: false,
    riskLevelsList: [],
    isEditingEnabled: false,
  };

  componentDidMount() {
    this.fetchQuestionnaireData();
  }

  fetchQuestionnaireData = async () => {
    await this.setState({ isLoading: true });
    await this.fetchRiskLevelList();
    await this.setQuestionnaire();
    await this.setState({ isLoading: false });
  };

  downloadUserQuestionnaire = async () => {
    const { userGuid } = this.props;

    try {
      await this.setState({ isLoading: true });
      const base64Doc = await api.getQuestionnairePersonProfile(userGuid);

      DownloadReceiptPDFService.handleDownload(base64Doc, `Profile_questionnaire_user_${userGuid}.pdf`);
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchRiskLevelList = async () => {
    try {
      const rawRiskLevelsList = await api.getPersonRiskLevels();
      this.setState({
        riskLevelsList: rawRiskLevelsList.map(({ LevelId, LevelDescription }) => ({
          value: LevelId,
          title: LevelDescription,
        })),
      });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  setQuestionnaire = async () => {
    const { setFormState, userQuestionnaire } = this.props;
    try {
      setFormState({
        ...userQuestionnaire,
        ...DATE_FIELDS.reduce(
          (acc, fieldName) => ({
            ...acc,
            [fieldName]: userQuestionnaire[fieldName] ? new Date(userQuestionnaire[fieldName]) : undefined,
          }),
          {},
        ),
        MonthlyPaymentLimit: userQuestionnaire.MonthlyPaymentLimit || '0',
        Pep: `${userQuestionnaire.Pep}`.trim().toLowerCase() === 'так',
      });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  onQuestionnaireSave = async () => {
    const { formState, translate, userGuid } = this.props;

    const emptyFields = Object.entries(formState).filter(
      ([key, value]) => REQUIRED_FIELDS.includes(key) && !value && value !== 0,
    );

    if (emptyFields.length) {
      message.error(
        `${translate(`page.terminalSettings.error-required-fields`)} ${emptyFields
          .map(([key, _]) => translate(LABEL_TRANSLATIONS[key]))
          .join(', ')}`,
        2.5,
      );

      return;
    }

    const { PostUserId, PostUser, CreateDate, ...restModelProps } = formState;

    const model = {
      ...restModelProps,
      UserId: userGuid,
      Pep: formState.Pep ? 'ТАК' : 'НІ',
      Phone: +`${formState.Phone}`.replaceAll(/[^0-9]/g, ''),
      ...DATE_FIELDS.filter((field) => field !== 'CreateDate').reduce(
        (acc, field) => ({
          ...acc,
          [field]: formState[field] ? getDataByTimezoneOffset(formState[field]).toISOString() : null,
        }),
        {},
      ),
      ...[...Object.keys(STATIC_SELECT_LIST_BY_FIELD_NAME), ...RISC_LEVEL_FIELDS].reduce(
        (acc, key) => ({
          ...acc,
          [key]: !restModelProps[key] && !restModelProps[key] !== 0 ? null : restModelProps[key],
        }),
        {},
      ),
      PostDate: getDataByTimezoneOffset(formState.PostDate || new Date()).toISOString(),
    };

    try {
      await this.setState({ isLoading: true });
      await api.updateFinMonPersonProfile(model);
      message.success(translate('page.clientCabinet.questionnaireSuccessfullySaved'));
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  onActionsSubmit = () => {
    if (!this.state.isEditingEnabled) {
      this.setState(({ isEditingEnabled }) => ({ isEditingEnabled: !isEditingEnabled }));
      return;
    }

    this.onQuestionnaireSave();
  };

  render() {
    const { formErrors, formState, onChangeFormState, onError } = this.props;
    const { isLoading, riskLevelsList, isEditingEnabled } = this.state;

    return (
      <>
        <Questionnaire
          formErrors={formErrors}
          formState={formState}
          onChangeFormState={onChangeFormState}
          riskLevelsList={riskLevelsList}
          downloadUserQuestionnaire={this.downloadUserQuestionnaire}
          isEditingEnabled={isEditingEnabled}
          onActionsSubmit={this.onActionsSubmit}
          setError={onError}
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

class QuestionnaireContainerWrapper extends PureComponent {
  render() {
    const { translate, user, showError, userQuestionnaire } = this.props;

    return (
      <FormStateContainer initialValues={{}}>
        <QuestionnaireContainer
          translate={translate}
          userGuid={user.UserGuid}
          showError={showError}
          userQuestionnaire={userQuestionnaire}
        />
      </FormStateContainer>
    );
  }
}

export default withTranslate(QuestionnaireContainerWrapper);
