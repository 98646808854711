export const LABEL_TRANSLATIONS = {
  FirstName: 'page.clientCabinet.firstName',
  LastName: 'page.clientCabinet.lastName',
  MiddleName: 'page.clientCabinet.middleName',
  Birthday: 'page.clientCabinet.birthday',
  Document: 'page.clientCabinet.passport',
  TaxNumber: 'page.clientCabinet.rnokpp',
  Phone: 'page.clientCabinet.phone',
  Citizenship: 'page.clientCabinet.citizenship',
  Residence: 'page.clientCabinet.residence',
  BirthPlace: 'page.clientCabinet.birthPlace',
  RecordNumber: 'page.clientCabinet.recordNumber',
  Employer: 'page.clientCabinet.employer',
  Position: 'page.clientCabinet.position',
  Employment: 'page.clientCabinet.employment',
  TotalMonthlyIncome: 'page.clientCabinet.totalMonthlyIncome',
  IncomeSources: 'page.clientCabinet.incomeSources',
  EasypayServices: 'page.clientCabinet.easypayServices',
  MonthlyPaymentsAmount: 'page.clientCabinet.monthlyPaymentsAmount',
  MonthlyPaymentLimit: 'page.clientCabinet.monthlyPaymentLimit',
  BanksAccountsTypes: 'page.clientCabinet.banksAccountsTypes',
  BanksDepositsAmount: 'page.clientCabinet.banksDepositsAmount',
  Securities: 'page.clientCabinet.securities',
  Email: 'page.clientCabinet.email',
  BusinessRelationsStartDate: 'page.clientCabinet.businessRelationsStartDate',
  RiskLevel: 'page.clientCabinet.riskLevel',
  RiskLevelDate: 'page.clientCabinet.riskLevelDate',
  ReassessmentRiskLevel1: 'page.clientCabinet.reassessmentRiskLevel1',
  ReassessmentRiskLevel2: 'page.clientCabinet.reassessmentRiskLevel2',
  ReassessmentRiskLevel3: 'page.clientCabinet.reassessmentRiskLevel3',
  ReassessmentRiskLevelDate1: 'page.clientCabinet.reassessmentRiskLevelDate1',
  ReassessmentRiskLevelDate2: 'page.clientCabinet.reassessmentRiskLevelDate2',
  ReassessmentRiskLevelDate3: 'page.clientCabinet.reassessmentRiskLevelDate3',
  CreateDate: 'page.clientCabinet.createDate',
  VerificationDate: 'page.clientCabinet.verificationDate',
  VerificationSource: 'page.clientCabinet.verificationSource',
  ActualizationInfo: 'page.clientCabinet.actualizationInfo',
  PersonCheckingInfo: 'page.clientCabinet.personCheckingInfo',
  PersonFinMonitoringInfo: 'page.clientCabinet.personFinMonitoringInfo',
  PostDate: 'page.clientCabinet.postDate',
  PersonRepresentativeInfo: 'page.clientCabinet.personRepresentativeInfo',
  IndividualEntrepreneurData: 'page.clientCabinet.individualEntrepreneurData',
  BusinessActivityTypes: 'page.clientCabinet.businessActivityTypes',
  IndependentProfessionalActivityTypes: 'page.clientCabinet.independentProfessionalActivityTypes',
  Pep: 'page.clientCabinet.RER',
  PepCategory: 'page.clientCabinet.RERCategory',
  PepPeriod: 'page.clientCabinet.RERPeriod',
  PepOriginPerson: 'page.clientCabinet.REROriginPerson',
  PepPosition: 'page.clientCabinet.RERPosition',
  PepDiscoveryDate: 'page.clientCabinet.RERDiscoveryDate',
  PepBusinessRelationsStartDate: 'page.clientCabinet.RERBusinessRelationsStartDate',
  PepActivitiesExpiredDate: 'page.clientCabinet.RERActivitiesExpiredDate',
  BusinessRelationsEndDate: 'page.clientCabinet.businessRelationsEndDate',
  AssetsFreezingDate: 'page.clientCabinet.assetsFreezingDate',
  AssetsUnFreezingDate: 'page.clientCabinet.assetsUnFreezingDate',
  PostUser: 'page.clientCabinet.postUserId',
};

export const DATE_FIELDS = [
  'RiskLevelDate',
  'Birthday',
  'BusinessRelationsStartDate',
  'ReassessmentRiskLevelDate1',
  'ReassessmentRiskLevelDate2',
  'ReassessmentRiskLevelDate3',
  'CreateDate',
  'VerificationDate',
  'PostDate',
  'PepDiscoveryDate',
  'PepBusinessRelationsStartDate',
  'PepActivitiesExpiredDate',
  'BusinessRelationsEndDate',
  'AssetsFreezingDate',
  'AssetsUnFreezingDate',
];

const MONTHLY_PAYMENT_LIMIT_LIST = [
  { title: '0', value: '0' },
  { title: '200000', value: '200000' },
  { title: '350000', value: '350000' },
];
export const VERIFICATION_SERVICE_LIST = [
  { title: 'EasyID - Дія', value: 'EasyID - Дія' },
  { title: 'Договір покладання', value: 'Договір покладання' },
];

export const STATIC_SELECT_LIST_BY_FIELD_NAME = {
  MonthlyPaymentLimit: MONTHLY_PAYMENT_LIMIT_LIST,
  VerificationSource: VERIFICATION_SERVICE_LIST,
};

export const RISC_LEVEL_FIELDS = [
  'RiskLevel',
  'ReassessmentRiskLevel1',
  'ReassessmentRiskLevel2',
  'ReassessmentRiskLevel3',
];

export const REQUIRED_FIELDS = [
  'LastName',
  'FirstName',
  'MiddleName',
  'Birthday',
  'Document',
  'TaxNumber',
  'Phone',
  'Citizenship',
  'Residence',
  'Employer',
  'Position',
  'Employment',
  'TotalMonthlyIncome',
  'IncomeSources',
  'EasypayServices',
  'MonthlyPaymentsAmount',
  'MonthlyPaymentLimit',
  'BanksAccountsTypes',
  'BanksDepositsAmount',
  'Securities',
  'RiskLevel',
  'CreateDate',
  'VerificationDate',
  'VerificationSource',
];
