import React, { PureComponent } from 'react';
import { Input } from 'antd';

import ExtendedAddressForm from '../../ExtendedAddressModal/ExtendedAddressForm';
import { withTranslate } from '../../../contexts/localContext';

import '../../ExtendedAddressModal/ExtendedAddressForm.styled.scss';

class TerminalRegistrationForm extends PureComponent {
  render() {
    const {
      formState: { katottg, ...restFormProps },
      formErrors,
      streetRoutes,
      onChangeFormState,
      translate,
    } = this.props;

    return (
      <div>
        <div className="ExtendedAddressForm-row">
          <div className="ExtendedAddressForm-fieldRow">
            <label className="ExtendedAddressForm-label">{translate('page.terminalSettings.modal-label')}</label>
            <Input
              type="text"
              value={katottg}
              onChange={({ target: { value } }) => {
                onChangeFormState('katottg', value);
              }}
              className="ExtendedAddressForm-input"
            />
          </div>
        </div>
        <ExtendedAddressForm
          formState={restFormProps}
          formErrors={formErrors}
          onChangeFormState={onChangeFormState}
          routes={streetRoutes}
          disabledFields
        />
      </div>
    );
  }
}

export default withTranslate(TerminalRegistrationForm);
