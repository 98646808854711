import {
  FIELD_TRANSLATION as ADDRESS_FIELD_TRANSLATION,
  REQUIRED_FIELD as REQUIRED_ADDRESS_FIELDS,
} from '../../../components/ExtendedAddressModal/contants';

class NewTerminalService {
  universalErrorMessage = (field, translate) => {
    return `${translate('page.terminalSettings.universalErrorMessage-field')}
    ${field} ${translate('page.terminalSettings.universalErrorMessage-first')}
    ${translate('page.terminalSettings.universalErrorMessage-second')}.`;
  };

  checkLatinSymbols(str) {
    return /[a-zA-Z]/.test(str);
  }

  validate = (state, translate) => {
    const {
      cityId,
      commisionProfileValue,
      mainProfilesListValue,
      capacityPrinterPaper,
      capacityCashBox,
      terminalName,
      terminalBranch,
      terminalsQuantity,
      addressStreetName,
      addressStreetType,
      addressBuilding,
      addressBuildingPart,
      addressCorp,
      addressApartment,
    } = state;

    if (!cityId && cityId == null) {
      return `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-city')}!`;
    }

    if (!commisionProfileValue && commisionProfileValue == null) {
      return `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-comissionProfile')}!`;
    }

    if (!mainProfilesListValue && mainProfilesListValue == null) {
      return `${translate('page.terminalSettings.error-required-fields')}
        ${translate('page.terminalSettings.field-mainScreenProfile')}!`;
    }

    if (
      capacityPrinterPaper &&
      capacityPrinterPaper !== '' &&
      (capacityPrinterPaper < 0 || capacityPrinterPaper > 6000)
    ) {
      return this.universalErrorMessage(`${translate('page.terminalSettings.field-printerCapacity')}`, translate);
    }

    if (capacityCashBox && capacityCashBox !== '' && (capacityCashBox < 0 || capacityCashBox > 3000)) {
      return this.universalErrorMessage(`${translate('page.terminalSettings.field-cashboxCapacity')}`, translate);
    }

    if (terminalName && terminalName !== '' && terminalName.length > 250) {
      console.log('terminalName', terminalName);
      return this.universalErrorMessage(`${translate('page.terminalSettings.field-name')}`, translate);
    }

    if (terminalBranch && terminalBranch !== '' && terminalBranch.length > 120) {
      return this.universalErrorMessage(`${translate('page.terminalSettings.field-branch')}`, translate);
    }

    if (!terminalsQuantity || !isFinite(terminalsQuantity) || terminalsQuantity < 1 || terminalsQuantity > 100) {
      return this.universalErrorMessage(`${translate('page.createNewTerminal.terminalsCount')}`, translate);
    }

    if (this.checkLatinSymbols(addressStreetName)) {
      return translate('page.terminalSettings.latin-address-error');
    }

    const isAddressFieldsTouched =
      addressStreetName ||
      addressStreetType ||
      addressBuilding ||
      addressBuildingPart ||
      addressCorp ||
      addressApartment;

    const emptyRequiredAddressFields = REQUIRED_ADDRESS_FIELDS.filter((field) => !state[field]);

    if (isAddressFieldsTouched && emptyRequiredAddressFields.length) {
      return `${translate('page.terminalSettings.error-required-fields')}
          ${emptyRequiredAddressFields
            .map((fieldName) => translate(ADDRESS_FIELD_TRANSLATION[fieldName]))
            .join(', ')}!`;
    }

    return '';
  };
}

export default new NewTerminalService();
