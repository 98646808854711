import axios, { CancelToken } from 'axios';
import StorageService from './storageService';
import { BASE_API_STRING } from '../core/constants/baseApiString.const';
import OwnerMessagesFetcher from './fetchOwnerMessages';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default class BaseApiService {
  constructor() {
    this.cancelSource = undefined;
    this.instance = axios.create(defaultOptions);
    this.instance.interceptors.request.use(this.handleRequest);
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  }

  handleRequest = (config) => {
    const token = StorageService.getItem('access_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  };

  getIsErrorMessageExist = (error) => error && error.response && error.response.data && error.response.data.Message;

  handleResponse = (response) => response;

  detectWrongTokenNotFound = (error) => {
    const isErrorExistByMessage =
      this.getIsErrorMessageExist(error) && error.response.data.Message === 'WRONG_TOKEN_USER_ID_NOT_FOUND';
    const isErrorCodeListExist =
      error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length;
    const isErrorExistByByErrorCodeList =
      isErrorCodeListExist &&
      error.response.data.errors.some(
        ({ message, extensions }) =>
          message === 'WRONG_TOKEN_USER_ID_NOT_FOUND' ||
          (extensions && extensions.code === 'WRONG_TOKEN_USER_ID_NOT_FOUND'),
      );

    return isErrorExistByMessage || isErrorExistByByErrorCodeList;
  };

  handleError = async (error) => {
    if (
      this.getIsErrorMessageExist(error) &&
      (error.response.data.Message === 'PASSWORD_EXPIRED_ERROR' ||
        error.response.data.Message === 'PASSWORD_INVALID' ||
        error.response.data.Message === 'USER_NAME_INVALID' ||
        error.response.data.Message === 'USER_NAME_OR_PASSWORD_INVALID' ||
        error.response.data.Message === 'USER_NOT_ACTIVE')
    ) {
      throw error;
    }

    if ((error.config && error.response && error.response.status === 401) || this.detectWrongTokenNotFound(error)) {
      try {
        this.cancelSource = undefined;
        let response = await this.refreshToken();
        StorageService.setItem('refresh_token', response.refreshToken);
        StorageService.setItem('access_token', response.accessToken);
        StorageService.setItem('userInfo', response);

        const model = {
          Elements: [],
        };
        const backEndConfig = await this.post(`${BASE_API_STRING}/Security/GetElements`, model);
        StorageService.setItem('backConfig', backEndConfig);

        error.config.headers.Authorization = `Bearer ${response.accessToken}`;
        const errorResponse = await this.instance.request(error.config);
        window.location.reload(true);
        return errorResponse;
      } catch (innerError) {
        return Promise.reject(innerError || innerError.response);
      }
    }

    if (axios.isCancel(error)) {
      return;
    }

    return Promise.reject(error.response);
  };

  post = async (url, body, isWithoutCancelToken = false) => {
    if (!isWithoutCancelToken) {
      this.cancel(url);

      this.cancelSource = CancelToken.source();
    }

    const response = await this.instance.post(url, body, {
      ...(isWithoutCancelToken ? {} : { cancelToken: this.cancelSource.token }),
      // responseType
    });

    OwnerMessagesFetcher.fetchOwnersMessages(this.instance);

    if (!isWithoutCancelToken) {
      this.cancelSource = undefined;
    }

    console.log('response', response);

    return response && response.data;
  };

  get = async (url, isWithoutCancelToken = false) => {
    if (!isWithoutCancelToken) {
      this.cancel(url);

      this.cancelSource = CancelToken.source();
    }

    const response = await this.instance.get(url, {
      ...(isWithoutCancelToken ? {} : { cancelToken: this.cancelSource.token }),
      // responseType,
      // Fix https://github.com/axios/axios/issues/86
      data: {},
    });

    OwnerMessagesFetcher.fetchOwnersMessages(this.instance);

    if (!isWithoutCancelToken) {
      this.cancelSource = undefined;
    }

    return response && response.data;
  };

  put = async (url, body) => {
    this.cancel(url);
    this.cancelSource = CancelToken.source();

    const response = await this.instance.put(url, body, {
      cancelToken: this.cancelSource.token,
      // responseType
    });

    this.cancelSource = undefined;
    return response.data;
  };

  delete = async (url, body) => {
    this.cancel(url);

    this.cancelSource = CancelToken.source();

    const response = await this.instance.delete(url, {
      cancelToken: this.cancelSource.token,
      data: body || {},
      // responseType,
      // Fix https://github.com/axios/axios/issues/86
      // data: {},
    });

    OwnerMessagesFetcher.fetchOwnersMessages(this.instance);

    this.cancelSource = undefined;
    return response.data;
  };

  cancel = (url) => {
    if (this.cancelSource !== undefined) {
      console.warn('Do cancel guery', url);
      this.cancelSource.cancel('cancel');
    }
  };

  refreshToken = async () => {
    try {
      const refreshToken = StorageService.getItem('refresh_token');
      if (refreshToken === null) throw new Error('Empty refresh_token');

      const body = JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken });
      const response = await axios.post(`${BASE_API_STRING}/Security/Token`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response && response.data;
    } catch (e) {
      throw e;
    }
  };
}
