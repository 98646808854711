import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Row, Col, Input, Select, Checkbox, Button as AntdButton, message } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { withRouter } from 'react-router-dom';

import { ApiService, tabService, StorageService, DownloadReceiptPDFService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import DateRange from '../../components/daterange';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import AlertModal from '../../components/grids/transactions/AlertModal';
import AdditionalTransactionsPropsModal from '../../components/AdditionalTransactions/AdditionalTransactionsPropsModal';
import { EmailReceiptsModal, ViberReceiptModal } from '../../components/TransactionReceiptActions';
import SplittingTransactionModal from '../../components/grids/transactions/SplittingTransactionModal';
import MergeTransactionsModal from '../../components/grids/transactions/MergeTransactionsModal';
import { TRANSACTION_PROPS } from '../../components/grids/transactions/constants';
import AddCommentDialog from '../../components/grids/transactions/AddCommentDialog';
import { TRANSACTION_STATUSES } from '../../core/constants/transactionStatuses';
import ChequeNumberMaskedInput from '../../components/grids/transactions/СhequeNumberMaskedInput';

import '../../components/grids/transactions/gridTransactions.scss';
import EditTransactionModal from '../../components/grids/transactions/EditTransactionModal';
import InformationModal from '../../components/grids/transactions/InformationModal';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';

export const TRANSACTION_FIELDS_INFO = {
  transactionId: { filter: 'numeric', title: 'grids.transactions.column-transactionId' },
  requestId: { filter: 'numeric', title: 'grids.transactions.column-requestId' },
  terminalId: { filter: 'numeric', title: 'grids.transactions.column-terminalId' },
  serviceId: { filter: 'numeric', title: 'grids.transactions.column-serviceId' },
  serviceName: { filter: 'text', title: 'grids.transactions.column-serviceName' },
  amountTotal: { filter: 'numeric', title: 'grids.transactions.column-amountTotal' },
  amountOriginal: { filter: 'numeric', title: 'grids.transactions.column-amountOriginal' },
  commissionClient: { filter: 'numeric', title: 'grids.transactions.column-comissionClient' },
  currency: { filter: 'text', title: 'grids.transactions.column-currency' },
  dateInput: { filter: 'date', title: 'grids.transactions.column-dateInput' },
  datePost: { filter: 'date', title: 'grids.transactions.column-datePost' },
  dateDeclined: { filter: 'date', title: 'grids.transactions.column-dateDeclined' },
  dateAccepted: { filter: 'date', title: 'grids.transactions.column-dateAccepted' },
  account: { filter: 'text', title: 'grids.transactions.column-account' },
  comment: { filter: 'text', title: 'grids.transactions.column-comment' },
  statusName: { filter: 'text', title: 'grids.transactions.column-status' },
  paymentType: { filter: 'text', title: 'grids.transactions.column-typePayment' },
  operationName: { filter: 'text', title: 'page.transactions.operation' },
  terminalType: { filter: 'text', title: 'page.transactions.terminalType' },
};

const { Option } = Select;
const api = new ApiService();
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
const aggregate = [
  { field: 'transactionId', aggregate: 'count', format: 'n0' },
  { field: 'terminalId', aggregate: 'count', format: 'n0' },
  { field: 'amountTotal', aggregate: 'sum', format: 'n2' },
  { field: 'amountOriginal', aggregate: 'sum', format: 'n2' },
  { field: 'commissionClient', aggregate: 'sum', format: 'n2' },
  { field: 'moneyTakerAmount', aggregate: 'sum', format: 'n2' },
];

class GridTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('financialTransactions');
    const defaultTransactionValue = StorageService.getItem('searchTransactionMainProp')
      ? StorageService.getItem('searchTransactionMainProp').transactionValue || TRANSACTION_PROPS[0]
      : TRANSACTION_PROPS[0];

    const defaultTransactionDetail = StorageService.getItem('searchTransactionMainProp')
      ? StorageService.getItem('searchTransactionMainProp').transactionDetail || ''
      : '';

    this.state = {
      owners: [],
      isLoading: false,
      data: [],
      rawData: [],
      statusValue: this.getInitialStatus(),
      transactionValue: defaultTransactionValue,
      transactionDetail: defaultTransactionDetail,
      range: {
        start: null,
        end: null,
      },
      chequeNumber: '',
      chequeNumberError: false,
      isNeedAdditionalFilter: StorageService.getItem('searchTransactionAdditionalFilter') === 'true',
      isSearchInHistory: StorageService.getItem('searchTransactionWithHistory') === 'true',
      selectedItems: [],
      dialogVariant: '',
      dialogStuff: null,
      isTransactionAmountLimitExceeded: false,
    };
  }

  componentDidMount() {
    this.fetchOwners();
  }

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  getInitialStatus = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    const storageValue = StorageService.getItem('searchtransactions-dropdown-status');
    if (val === 'blocked') return TRANSACTION_STATUSES[6].value;
    else if (storageValue !== null) return storageValue;
    else return TRANSACTION_STATUSES[2].value;
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '0.4' : '0.35';
    switch (value) {
      case 'принят':
        return `rgba(92, 184, 92, ${opacity})`;
      case 'отклонен':
        return `rgba(205, 92, 92, ${opacity})`;
      case 'удален':
        return `rgba(244, 164, 96, ${opacity})`;
      case 'заблокирован':
        return `rgba(70, 130, 180, ${opacity})`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.statusName;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle, color: 'black' };
    return React.cloneElement(element, { style }, children);
  };

  changeDateSeconds = (formatedDate) => new Date(formatedDate);

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    const startDate = new Date(start).setMilliseconds(0);
    const endDate = new Date(end).setMilliseconds(0);
    if (el && el.datePost) {
      const changedDate = this.changeDateSeconds(el.datePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  filterFurther = (data) => {
    const { isNeedAdditionalFilter } = this.state;

    return isNeedAdditionalFilter ? data.filter((el) => this.filterByDatePost(el)) : data;
  };

  checkChequeNumber = (chequeNumber) => {
    if (chequeNumber.replaceAll('_', '').replaceAll('-', '') === '') {
      return false;
    }

    return (chequeNumber || chequeNumber === 0) && !/^\b\d{5,7}\b-\b\d{1,9}\b-\b\d{1,9}\b$/.test(chequeNumber);
  };

  fetchTransactionInfo = async (transactionList) => {
    try {
      const data = await api.getByGraphQl(
        `transactions(order: {transactionId: ASC}, where:{and: [ {transactionId: {in: [${transactionList.join(
          ',',
        )}]}}]}){${Object.keys(TRANSACTION_FIELDS_INFO).join()}} `,
      );

      this.setState({
        data:
          data && data.data && data.data.transactions && data.data.transactions.length
            ? this.filterFurther(data.data.transactions)
            : [],
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  fetchTransactionList = async () => {
    const { currentOwners, statusValue, transactionValue, range, chequeNumber, transactionDetail, isSearchInHistory } =
      this.state;

    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);
    const isChequeNumberUnValid = this.checkChequeNumber(chequeNumber);

    this.setState({ chequeNumberError: isChequeNumberUnValid });

    if (isChequeNumberUnValid) {
      return;
    }

    try {
      await this.setState({ isTransactionAmountLimitExceeded: false, isLoading: true });

      const model = {
        Owners: [0],
        StatusId: Number(statusValue),
        ChequeNumber: chequeNumber.replaceAll('_', '').replaceAll('-', '') === '' ? '' : chequeNumber,
        DetailName: TRANSACTION_PROPS[0] === transactionValue ? '' : transactionValue,
        DetailValue: transactionDetail,
        DateStart: start,
        DateEnd: end,
        DisplayComments: true,
        DisplayUser: true,
        Hist: true,
      };

      const data = await api.transactions(model);

      this.setState({
        data:
          data && data.length
            ? this.filterFurther(
                data.map((item) =>
                  Object.entries(item).reduce(
                    (acc, [key, value]) => ({ ...acc, [lowerCaseFirstLetter(key)]: value }),
                    {},
                  ),
                ),
              )
            : [],
        isLoading: false,
      });

      //
      // if (transactionValue === 'TransactionId' && transactionDetail) {
      //   await this.fetchTransactionInfo([transactionDetail]);
      //
      //   return;
      // }

      // const model = {
      //   Owners: [0],
      //   StatusId: Number(statusValue),
      //   ChequeNumber: chequeNumber.replaceAll('_', '').replaceAll('-', '') === '' ? '' : chequeNumber,
      //   DetailName: TRANSACTION_PROPS[0] === transactionValue ? '' : transactionValue,
      //   DetailValue: transactionDetail,
      //   DateStart: start,
      //   DateEnd: end,
      //   DisplayComments: true,
      //   DisplayUser: true,
      //   Hist: isSearchInHistory,
      // };
      //
      // const transactionList = await api.findTransactionForGraph(model);
      //
      // if (!transactionList.length) {
      //   this.setState({ data: [] });
      //   this.toggleLoader(false);
      //   return;
      // }
      //
      // this.setState({ isTransactionAmountLimitExceeded: transactionList.length >= 5000 });
      // await this.fetchTransactionInfo(transactionList.map(({ TransactionId }) => TransactionId));
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  checkNullDate = (date) => date && new Date(date).getFullYear() === 1970;

  formatDate = (data) => {
    if (!data) {
      return [];
    }

    return data.map(
      ({
        dateAccepted,
        dateAcceptedNoUTC,
        dateDeclined,
        dateDeclinedNoUTC,
        dateInput,
        dateInputNoUTC,
        datePost,
        datePostNoUTC,
        ...restFields
      }) => ({
        ...restFields,
        dateAccepted: this.checkNullDate(dateAccepted) ? null : dateAccepted,
        dateAcceptedNoUTC: this.checkNullDate(dateAcceptedNoUTC) ? null : dateAcceptedNoUTC,
        dateDeclined: this.checkNullDate(dateDeclined) ? null : dateDeclined,
        dateDeclinedNoUTC: this.checkNullDate(dateDeclinedNoUTC) ? null : dateDeclinedNoUTC,
        dateInput: this.checkNullDate(dateInput) ? null : dateInput,
        dateInputNoUTC: this.checkNullDate(dateInputNoUTC) ? null : dateInputNoUTC,
        datePost: this.checkNullDate(datePost) ? null : datePost,
        datePostNoUTC: this.checkNullDate(datePostNoUTC) ? null : datePostNoUTC,
      }),
    );
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range: value });
  };

  onStatusChange = (value) => {
    this.setState({ statusValue: value }, () => StorageService.setItem('searchtransactions-dropdown-status', value));
  };

  onTransactionChange = (value) => {
    this.setState(
      () => {
        return {
          transactionValue: value,
        };
      },
      () => {
        const searchTransactionMainProp = StorageService.getItem('searchTransactionMainProp');

        StorageService.setItem('searchTransactionMainProp', {
          transactionValue: this.state.transactionValue,
          transactionDetail: searchTransactionMainProp ? searchTransactionMainProp.transactionDetail || '' : '',
        });
      },
    );
  };

  onChequeNumberChange = (e) => {
    this.setState({ chequeNumber: e.target.value });
  };

  onTransactionDetailChange = (e) => {
    this.setState({ transactionDetail: e.target.value }, () => {
      const searchTransactionMainProp = StorageService.getItem('searchTransactionMainProp');

      StorageService.setItem('searchTransactionMainProp', {
        transactionValue: searchTransactionMainProp
          ? searchTransactionMainProp.transactionValue || TRANSACTION_PROPS[0]
          : TRANSACTION_PROPS[0],
        transactionDetail: this.state.transactionDetail,
      });
    });
  };

  ifBlocked = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    if (val === 'blocked') {
      return true;
    }
    return false;
  };

  buildToolbar = () => {
    const {
      statusValue,
      transactionValue,
      chequeNumber,
      transactionDetail,
      isNeedAdditionalFilter,
      isSearchInHistory,
      owners,
      currentOwners,
      selectedItems,
      isTransactionAmountLimitExceeded,
    } = this.state;
    const { translate } = this.props;
    return (
      <Row type="flex" justify="start" gutter={16} xs={24} lg={24} span={24}>
        <Col xs={24} lg={24} xl={21} span={24} className="GridTransactions-fieldsCol">
          <Col xs={24} lg={24} xl={14} span={24}>
            <DateRange
              // shortFormat
              onRange={this.setRange}
              blocked={this.ifBlocked()}
            />
          </Col>

          <Col xs={24} lg={12} xl={4} span={24} className="lg-mt-10">
            <Row type="flex" align="middle" justify="start">
              <Col span={24}>
                <Col>
                  <Select
                    value={statusValue}
                    onChange={(value) => this.onStatusChange(value)}
                    placeholder="Статус"
                    style={{ width: '100%' }}
                  >
                    {TRANSACTION_STATUSES.map(({ value, title }) => (
                      <Option value={value}>{title}</Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <ChequeNumberMaskedInput
                    value={chequeNumber}
                    placeholder="№ чека"
                    onChange={this.onChequeNumberChange}
                  />
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={12} xl={6} className="lg-mt-10">
            <Row>
              <Select value={transactionValue} onChange={this.onTransactionChange} style={{ width: '100%' }}>
                {TRANSACTION_PROPS.map((option, index) => (
                  <Option key={`transactionProps-${index.toString()}`} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Input
                value={transactionDetail}
                placeholder={translate('page.transactions.value')}
                onChange={this.onTransactionDetailChange}
                className="transaction-input"
              />
            </Row>
          </Col>

          <Col span={24} className="GridTransactions-checkboxActionsRow">
            <div className="GridTransactions-checkboxRow">
              <Checkbox
                checked={isNeedAdditionalFilter}
                onChange={() => {
                  this.setState(
                    ({ isNeedAdditionalFilter }) => ({ isNeedAdditionalFilter: !isNeedAdditionalFilter, data: [] }),
                    () => {
                      StorageService.setItem(
                        'searchTransactionAdditionalFilter',
                        `${this.state.isNeedAdditionalFilter}`,
                      );
                    },
                  );
                }}
              />

              <div>{translate('core.toolbar.filterByData')}</div>
            </div>

            <div className="GridTransactions-checkboxRow">
              <Checkbox
                checked={isSearchInHistory}
                onChange={() => {
                  this.setState(
                    ({ isSearchInHistory }) => ({ isSearchInHistory: !isSearchInHistory }),
                    () => {
                      StorageService.setItem('searchTransactionWithHistory', `${this.state.isSearchInHistory}`);
                      this.setState({ data: [] });
                    },
                  );
                }}
              />

              <div>{translate('page.transactions.searchInHistory')}</div>
            </div>

            <div>
              <AntdButton
                type="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                  this.setState({ dialogVariant: 'additionalProps' });
                }}
              >
                {translate('page.transactions.transactionWithAdditionalProps')}
              </AntdButton>
            </div>

            <div>
              <AntdButton
                type="primary"
                onClick={() => {
                  this.setState({
                    dialogVariant: 'addComment',
                    dialogStuff: selectedItems.map(({ transactionId }) => transactionId),
                  });
                }}
                disabled={!selectedItems.length}
              >
                {translate('page.transactions.addComment')}
              </AntdButton>
            </div>

            <div>
              <AntdButton
                type="primary"
                onClick={() => {
                  this.setState({
                    dialogVariant: 'reProcessingTransaction',
                    dialogStuff: { selectedItems: selectedItems },
                  });
                }}
                disabled={!selectedItems.length}
              >
                {translate('page.transactions.reProcessingTransaction')}
              </AntdButton>
            </div>
          </Col>
        </Col>

        <Col xs={24} lg={24} xl={3} span={24} className="lg-mt-10 GridTransactions-searchActionWrapper">
          <Button className="sm-w-100" icon="search" primary onClick={this.fetchTransactionList}>
            {translate('owner.search')}
          </Button>
        </Col>

        {(isSearchInHistory || isTransactionAmountLimitExceeded) && (
          <Col span={24} className="GridTransactions-messages">
            {isSearchInHistory && (
              <div className="GridTransactions-warningMessage">
                {translate('page.transactions.searchInHistWarning')}
              </div>
            )}

            {isTransactionAmountLimitExceeded && (
              <div className="GridTransactions-errorMessage">
                {translate('page.transactions.limitSearchParameters')}
              </div>
            )}
          </Col>
        )}
      </Row>
    );
  };

  getHandlers = () => {
    const { showError, translate } = this.props;
    const { selectedItems } = this.state;

    return [
      {
        title: 'page.transactions.copyTransactionId',
        action: async (props) => {
          await navigator.clipboard.writeText(`${props.dataItem.transactionId}`);
          message.success(translate('page.transactions.transactionNumberIsCopied'), 0.5);
        },
        dropdown: false,
        show: true,
      },
      {
        title: 'page.transactions.editTransaction',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'editTransaction', dialogStuff: dataItem });
        },
        dropdown: false,
        show: true,
      },
      {
        title: 'grids.transactions.button-details',
        action: ({ dataItem }) => tabService.addTab({ type: 'details', dataItem: dataItem }),
        dropdown: false,
        show: true,
      },
      {
        title: 'page.transactions.additionalInfo',
        action: (props) => tabService.addTab({ type: 'additionalInfo', dataItem: props.dataItem }),
        dropdown: false,
        show: true,
      },
      {
        title: 'page.transactions.sendingEmailReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingEmailReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: true,
      },

      {
        title: 'page.transactions.sendingViberReceipt',
        action: ({ dataItem }) => {
          this.setState({ dialogVariant: 'sendingViberReceipt', dialogStuff: dataItem });
        },
        dropdown: false,
        show: true,
      },

      {
        title: 'page.transactions.downloadReceipt',
        action: ({ dataItem }) => {
          DownloadReceiptPDFService.downloadReceipt(
            async (isLoading) => {
              await this.setState({ isLoading });
            },
            showError,
            dataItem.transactionId,
          );
        },
        dropdown: false,
        show: true,
      },
      // {
      //   title: 'page.transactions.split',
      //   action: ({ dataItem }) => {
      //     this.setState({ dialogVariant: 'splittingTransaction', dialogStuff: dataItem });
      //   },
      //   dropdown: false,
      //   show: true,
      // },
      // {
      //   title: 'page.transactions.mergeTransactions',
      //   action: ({ dataItem }) => {
      //     this.setState({
      //       dialogVariant: 'mergeTransactions',
      //       dialogStuff: {
      //         firstTransactionId: dataItem.transactionId,
      //         secondTransactionId:
      //           selectedItems[0] && selectedItems[0].transactionId !== dataItem.transactionId
      //             ? selectedItems[0].transactionId
      //             : '',
      //       },
      //     });
      //   },
      //   dropdown: false,
      //   show: true,
      //   disabled: selectedItems.length > 1,
      // },
      {
        type: 'divider',
      },
      {
        title: 'page.transactions.rejectTransaction',
        action: ({ dataItem }) => {
          this.setState({
            dialogVariant: 'rejectTransaction',
            dialogStuff: { dataItem: dataItem, selectedItems: selectedItems },
          });
        },
        dropdown: false,
        show: true,
      },
      {
        title: 'page.transactions.unlockTransaction',
        action: ({ dataItem }) => {
          this.setState({
            dialogVariant: 'unlockTransaction',
            dialogStuff: { dataItem: dataItem, selectedItems: selectedItems },
          });
        },
        dropdown: false,
        show: true,
      },
    ];
  };

  onAdditionalPropSelected = (selectedAdditionalProps) => {
    const { selectedItems } = this.state;
    tabService.addTab({
      type: 'additionalProps',
      dataItem: {
        selectedAdditionalProps,
        selectedItems,
      },
    });
  };

  renderBaseGridColumn = ({ field, ...props }) => (
    <GridColumn
      field={field}
      title={TRANSACTION_FIELDS_INFO[field].title}
      filter={TRANSACTION_FIELDS_INFO[field].filter}
      {...props}
    />
  );

  render = () => {
    const { isLoading, data, chequeNumberError, dialogVariant, dialogStuff, transactionValue } = this.state;

    const { showError } = this.props;

    if (!checkElement('financialTransactions-content', this.currentArray)) {
      return null;
    }

    const informationModalVariants = ['reProcessingTransaction', 'unlockTransaction', 'rejectTransaction'];
    const openInformationModal = informationModalVariants.includes(dialogVariant);

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.fetchTransactionList}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          rowRender={this.rowRender}
          name="terminalTransactions"
          fieldForSelection="transactionId"
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          nonGqlExportFormatter={this.formatDate}
          filterConstructorStaff={{ fieldsInfo: TRANSACTION_FIELDS_INFO }}
          multiSelected
          withOutBaseButtons
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            field="transactionId"
            title={TRANSACTION_FIELDS_INFO.transactionId.title}
            filter={TRANSACTION_FIELDS_INFO.transactionId.filter}
            width="130"
            handlers={this.getHandlers()}
            dropdown
          />
          {this.renderBaseGridColumn({ field: 'requestId', with: '112' })}
          {this.renderBaseGridColumn({ field: 'terminalId', with: '124' })}
          {this.renderBaseGridColumn({ field: 'serviceId', with: '110' })}
          {this.renderBaseGridColumn({ field: 'serviceName', with: '93' })}
          {this.renderBaseGridColumn({ field: 'amountTotal', with: '134', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'amountOriginal', with: '180', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'commissionClient', with: '171', format: '{0:n2}' })}
          {this.renderBaseGridColumn({ field: 'currency', with: '90' })}
          {this.renderBaseGridColumn({ field: 'dateInput', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'datePost', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'dateDeclined', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'dateAccepted', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
          {this.renderBaseGridColumn({ field: 'account', with: '112' })}
          {this.renderBaseGridColumn({ field: 'comment', with: '125' })}
          {this.renderBaseGridColumn({ field: 'statusName', with: '91' })}
          {this.renderBaseGridColumn({ field: 'paymentType', with: '125' })}
          {this.renderBaseGridColumn({ field: 'operationName', with: '125' })}
          {this.renderBaseGridColumn({ field: 'terminalType', with: '125' })}
          {/**/}
        </Grid>
        {chequeNumberError && (
          <AlertModal
            visible={chequeNumberError}
            closeModal={() => {
              this.setState({ chequeNumberError: false });
            }}
          />
        )}
        {dialogVariant === 'additionalProps' && (
          <AdditionalTransactionsPropsModal
            visible={dialogVariant === 'additionalProps'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            onSave={this.onAdditionalPropSelected}
          />
        )}
        {dialogVariant === 'sendingEmailReceipt' && (
          <EmailReceiptsModal
            visible={dialogVariant === 'sendingEmailReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.transactionId}
          />
        )}
        {dialogVariant === 'sendingViberReceipt' && (
          <ViberReceiptModal
            visible={dialogVariant === 'sendingViberReceipt'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            transactionId={dialogStuff.transactionId}
          />
        )}
        {dialogVariant === 'splittingTransaction' && (
          <SplittingTransactionModal
            visible={dialogVariant === 'splittingTransaction'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'editTransaction' && (
          <EditTransactionModal
            visible={dialogVariant === 'editTransaction'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'mergeTransactions' && (
          <MergeTransactionsModal
            visible={dialogVariant === 'mergeTransactions'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}
        {dialogVariant === 'addComment' && (
          <AddCommentDialog
            visible={dialogVariant === 'addComment'}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            selectedTransactionIds={dialogStuff}
            onRefresh={this.fetchTransactionList}
          />
        )}

        {openInformationModal && (
          <InformationModal
            visible={openInformationModal}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
            dialogStuff={dialogStuff}
            dialogVariant={dialogVariant}
            onRefresh={this.fetchTransactionList}
            showError={showError}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridTransactions));
