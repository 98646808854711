const messages = (translate, value) => {
  switch (value) {
    case 'messagesList':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'create-tab-singleMessage':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('page.messages.tab-single')}`;
    // case 'messagesList-MessageId':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-messageId')}`;
    // case 'messagesList-Subject':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-subject')}`;
    // case 'messagesList-DatePost':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-datePost')}`;
    // case 'messagesList-StatusName':
    //   return `${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-statusName')}`;
    // case 'singleMessage-Subject':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.messages.tab-single')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-subject')}`;
    // case 'singleMessage-Body':
    //   return `${translate('page.adminPanel.type-tab')}:
    //           ${translate('page.messages.tab-single')},
    //           ${translate(`page.adminPanel.type-column`)}:
    //           ${translate('grids.messages.column-text')}`;
    default:
      return 'unknown';
  }
};

export default messages;
