export const FIELD_TRANSLATION = {
  addressStreetType: 'page.terminalSettings.streetType',
  addressStreetName: 'page.terminalSettings.streetName',
  addressBuilding: 'page.terminalSettings.addressBuilding',
  addressBuildingPart: 'page.terminalSettings.addressBuildingPart',
  addressCorp: 'page.terminalSettings.addressCorp',
  addressApartment: 'page.terminalSettings.addressApartment',
};

export const REQUIRED_FIELD = ['addressStreetType', 'addressStreetName'];
