import React, { PureComponent } from 'react';
import { Select } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import { FIELD_TRANSLATION } from './contants';
import CustomInput from '../Input/Input';

import './ExtendedAddressForm.styled.scss';

const { Option } = Select;

class ExtendedAddressForm extends PureComponent {
  selectRef = React.createRef();

  renderRow = ({ name }) => {
    const { formState, onChangeFormState, formErrors, translate, disabledFields, onFieldsBlur, onFieldsFocus } =
      this.props;

    return (
      <div className="ExtendedAddressForm-fieldRow">
        <div className="ExtendedAddressForm-label">{translate(FIELD_TRANSLATION[name])}</div>
        <div className="ExtendedAddressForm-input">
          <CustomInput
            formState={formState}
            onChangeFormState={onChangeFormState}
            name={name}
            formErrors={formErrors}
            disabled={disabledFields}
            onBlur={onFieldsBlur || undefined}
            onFocus={onFieldsFocus || undefined}
          />
        </div>
      </div>
    );
  };

  render() {
    const { translate, onChangeFormState, formState, routes, disabledFields, onFieldsBlur, onFieldsFocus } = this.props;

    return (
      <div>
        <div className="ExtendedAddressForm">
          <div className="ExtendedAddressForm-row">
            <div className="ExtendedAddressForm-fieldRow">
              <div className="ExtendedAddressForm-label">{translate(FIELD_TRANSLATION.addressStreetType)}</div>
              <div className="ExtendedAddressForm-input">
                <Select
                  ref={this.selectRef}
                  placeholder={translate('page.profilesMenu.selectLanguage')}
                  onChange={(value) => {
                    onChangeFormState('addressStreetType', value);
                  }}
                  value={formState.addressStreetType}
                  disabled={disabledFields}
                  onBlur={onFieldsBlur}
                  onFocus={() => {
                    if (this.selectRef.current && this.selectRef.current.blur && onFieldsFocus) {
                      this.selectRef.current.blur();

                      if (onFieldsFocus) {
                        onFieldsFocus();
                      }
                    }
                  }}
                >
                  {routes.map(({ StreetTypeId, Name }) => (
                    <Option value={StreetTypeId} key={StreetTypeId}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {this.renderRow({ name: 'addressStreetName' })}
          </div>

          <div className="ExtendedAddressForm-row">
            {this.renderRow({ name: 'addressBuilding' })}
            {this.renderRow({ name: 'addressBuildingPart' })}
          </div>

          <div className="ExtendedAddressForm-row">
            {this.renderRow({ name: 'addressCorp' })}
            {this.renderRow({ name: 'addressApartment' })}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(ExtendedAddressForm);
