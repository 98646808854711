import React, { PureComponent } from 'react';
import { Select, Button } from 'antd';

import CustomInput from '../../../components/Input/Input';
import SupplierPropsService from './SupplierProps.service';
import { withTranslate } from '../../../contexts/localContext';
import BeneficiaryGrid from './BeneficiaryGrid';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { DIRECTORS_COLUMN_CONFIG } from './constants';

import './SupplierProps.styled.scss';

const { Option } = Select;

class SupplierProps extends PureComponent {
  renderInputRow = ({ label, inputComponent, isShort, isLong }) => {
    const { translate } = this.props;

    return (
      <div className="SupplierProps-inputRow">
        <div
          className={`SupplierProps-label ${isShort ? 'SupplierProps-shortLabel' : ''} ${
            isLong ? 'SupplierProps-longLabel' : ''
          }`}
        >
          {label ? translate(label) : ''}
        </div>
        <div className="SupplierProps-input">{inputComponent}</div>
      </div>
    );
  };

  renderInput = (name, type, isHandleDisabled = false, props = {}) => {
    const { translate, formState, onChangeFormState, formErrors, onError } = this.props;

    return (
      <CustomInput
        type={type}
        formState={formState}
        disabled={isHandleDisabled}
        onChangeFormState={onChangeFormState}
        formErrors={formErrors}
        setError={onError}
        validators={SupplierPropsService.getValidators(translate)}
        name={name}
        {...props}
      />
    );
  };

  render() {
    const {
      servicesSuppliersCeo,
      formState,
      onChangeFormState,
      translate,
      servicesSuppliersBeneficiary,
      regionList,
      isActionDisabled,
      finMonRiskList,
      onSave,
    } = this.props;

    return (
      <div className="SupplierProps">
        <div className="SupplierProps-row">
          {this.renderInputRow({
            label: 'page.serviceSupplier.col-number',
            inputComponent: this.renderInput('supplierId', 'string', true),
          })}

          {this.renderInputRow({
            label: 'page.serviceSupplier.changeDate',
            inputComponent: this.renderInput('changeDate', 'datePicker', true, { format: 'dd.MM.yyyy HH:mm:ss' }),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.col-name',
            inputComponent: this.renderInput('name'),
          })}
        </div>

        <div className="SupplierProps-row">
          {this.renderInputRow({
            label: 'page.serviceSupplier.col-EDRPOU',
            inputComponent: this.renderInput('EDRPOU'),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.address',
            inputComponent: this.renderInput('address', 'textArea'),
          })}
        </div>

        <div className="SupplierProps-row">
          {this.renderInputRow({
            label: 'page.serviceSupplier.phone',
            inputComponent: this.renderInput('phone'),
          })}

          {this.renderInputRow({
            isShort: true,
            label: 'page.serviceSupplier.fax',
            inputComponent: this.renderInput('fax'),
          })}
        </div>

        <div className="SupplierProps-row">
          {this.renderInputRow({
            label: '',
            inputComponent: this.renderInput('email'),
          })}

          {this.renderInputRow({
            isShort: true,
            label: 'www',
            inputComponent: this.renderInput('webAddress'),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.description',
            inputComponent: this.renderInput('description', 'textArea'),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.region',
            inputComponent: (
              <Select
                value={formState.region}
                placeholder={translate('page.screenControlBusinessServices.default-region-value')}
                onChange={(region) => {
                  onChangeFormState('region', region);
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                showSearch
              >
                {regionList.map(({ RegionName, RegionId }) => (
                  <Option value={RegionId} key={`region-key-${RegionId}-${RegionName}`}>
                    {RegionName}
                  </Option>
                ))}
              </Select>
            ),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            isLong: true,
            label: 'page.serviceSupplier.authorized',
            inputComponent: this.renderInput('verified', 'checkbox'),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            isLong: true,
            label: 'page.serviceSupplier.RERAffiliation',
            inputComponent: this.renderInput('isAffiliation', 'checkbox'),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.finmonLevelRisk',
            inputComponent: (
              <Select
                value={formState.riskLevel || undefined}
                placeholder={translate('page.serviceSupplier.selectRiskLevel')}
                onChange={(riskLevel) => {
                  onChangeFormState('riskLevel', riskLevel);
                }}
              >
                {finMonRiskList.map(({ Id, Value }) => (
                  <Option value={Id} key={`region-key-${Id}-${Value}`}>
                    {Value}
                  </Option>
                ))}
              </Select>
            ),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.director',
            inputComponent: (
              <GridDropdown
                data={servicesSuppliersCeo}
                colConfig={DIRECTORS_COLUMN_CONFIG}
                selectItems={formState.director}
                onSave={(selectItems) => {
                  onChangeFormState('director', selectItems);
                }}
                defaultTitle={translate('page.serviceSupplier.selectDirector')}
              />
            ),
          })}
        </div>

        <div className="SupplierProps-singleRow">
          {this.renderInputRow({
            label: 'page.serviceSupplier.beneficiary',
            inputComponent: (
              <GridDropdown
                data={servicesSuppliersBeneficiary}
                colConfig={DIRECTORS_COLUMN_CONFIG}
                selectItems={formState.beneficiary}
                onSave={(selectItems) => {
                  onChangeFormState('beneficiary', selectItems);
                }}
                defaultTitle={translate('page.serviceSupplier.selectBeneficiary')}
              />
            ),
          })}
        </div>

        <div className="SupplierProps-beneficiaryGridRow">
          <BeneficiaryGrid
            servicesSuppliersBeneficiary={servicesSuppliersBeneficiary.filter(({ Id }) =>
              formState.beneficiary.includes(Id),
            )}
          />
        </div>

        <Button disabled={isActionDisabled} type="primary" onClick={onSave}>
          {translate('core.buttonTitles.save')}
        </Button>
      </div>
    );
  }
}

export default withTranslate(SupplierProps);
