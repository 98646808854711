import { CashDept_MIN_VALID_DATE } from './CashDept';

class CashDeptService {
  validateDiffDates = (translate, startDate, endDate) => {
    const diffDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24));
    return diffDays > 31 ? translate('page.cashDebt.period') : '';
  };

  normalizeDate = (date) => {
    const currentMonthString = `${('0' + (new Date(date).getMonth() + 1)).slice(-2)}`;
    const currentDateString = `${('0' + new Date(date).getDate()).slice(-2)}`;

    return `${new Date(date).getFullYear()}${currentMonthString}${currentDateString}`;
  };

  getSubmitErrorsByDateTrasnslate = (startDate, endDate, translate) => {
    if (startDate < CashDept_MIN_VALID_DATE) {
      return translate('page.cashDept.minDateError');
    }

    if (startDate - endDate > 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const diffDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24));

    if (diffDays > 31) {
      return translate('page.cashDebt.period');
    }

    return '';
  };

  getSubmitErrorsByDate = (startDate, endDate) => {
    if (startDate < CashDept_MIN_VALID_DATE) {
      return true;
    }

    if (startDate - endDate > 0) {
      return true;
    }

    const diffDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24));

    if (diffDays > 31) {
      return true;
    }
    return false;
  };

  normalizeResponseData = (data) => {
    const dealers = data && data.Companies && data.Companies.length && data.Companies[0].Dealers;
    if (!dealers) {
      return [];
    }

    return dealers.reduce((acc, { DealerCode, DealerName, Periods }) => {
      const currentDealerPeriods = Periods.map(({ Transactions, ...restPeriod }) => ({
        ...restPeriod,
        DealerCode,
        DealerName,
      }));
      return [...acc, ...currentDealerPeriods];
    }, []);
  };
}

export default new CashDeptService();
