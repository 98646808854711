export const ROUTE_ALIASES_LIST = [
  { id: 2, slugList: ['вулиця', 'вул.'], translateSlug: 'вулиця' },
  { id: 3, slugList: ['проспект', 'пр.'], translateSlug: 'проспект' },
  { id: 4, slugList: ['провулок'], translateSlug: 'провулок' },
  { id: 5, slugList: ['бульвар'], translateSlug: 'бульвар' },
  { id: 6, slugList: ['площа', 'пл.'], translateSlug: 'площа' },
  { id: 7, slugList: ['узвіз'], translateSlug: 'узвіз' },
  { id: 8, slugList: ['парк', 'Парк'], translateSlug: 'парк' },
  { id: 9, slugList: ['лінія'], translateSlug: 'лінія' },
  { id: 10, slugList: ['тупик'], translateSlug: 'тупик' },
  { id: 11, slugList: ['сквер', 'Сквер'], translateSlug: 'сквер' },
  { id: 12, slugList: ['дорога', 'дор.'], translateSlug: 'дорога' },
  { id: 13, slugList: ['набережна'], translateSlug: 'набережна' },
  { id: 14, slugList: ["об'їзд", 'об.'], translateSlug: "об'їзд" },
  { id: 15, slugList: ["в'їзд"], translateSlug: "в'їзд" },
  { id: 16, slugList: ['тракт'], translateSlug: 'тракт' },
  { id: 17, slugList: ['шосе'], translateSlug: 'шосе' },
  { id: 18, slugList: ['проїзд'], translateSlug: 'проїзд' },
  { id: 19, slugList: ['шлях'], translateSlug: 'шлях' },
  { id: 20, slugList: ['алея', 'Алея'], translateSlug: 'алея' },
  { id: 21, slugList: ['спуск'], translateSlug: 'спуск' },
  { id: 22, slugList: ['квартал'], translateSlug: 'квартал' },
  { id: 23, slugList: ['мікрорайон', 'микрорайон'], translateSlug: 'мікрорайон' },
  { id: 24, slugList: ['територія'], translateSlug: 'територія' },
  { id: 25, slugList: ['житломасив', 'ж/м'], translateSlug: 'житломасив' },
  { id: 26, slugList: ['майдан'], translateSlug: 'майдан' },
  { id: 27, slugList: ['урочище'], translateSlug: 'урочище' },
  { id: 28, slugList: ['містечко'], translateSlug: 'містечко' },
  { id: 29, slugList: ['дільниця'], translateSlug: 'дільниця' },
  { id: 30, slugList: ['підстанція'], translateSlug: 'підстанція' },
  { id: 31, slugList: ['військ.час', 'в/ч', 'в.ч.', 'в./ч.', 'військова частина'], translateSlug: 'військ.час' },
  { id: 32, slugList: ['селище'], translateSlug: 'селище' },
  { id: 33, slugList: ['пляж', 'Пляж'], translateSlug: 'пляж' },
  { id: 34, slugList: ['причал', 'Причал'], translateSlug: 'причал' },
  { id: 35, slugList: ['садиба'], translateSlug: 'садиба' },
  { id: 36, slugList: ["роз'їзд", "Роз'їзд"], translateSlug: "роз'їзд" },
  { id: 37, slugList: ['заїзд', 'Заїзд'], translateSlug: 'заїзд' },
  { id: 38, slugList: ['переїзд', 'Переїзд'], translateSlug: 'переїзд' },
  { id: 39, slugList: ['станція', 'ст. м.', 'ст.'], translateSlug: 'станція' },
  { id: 40, slugList: ['курорт'], translateSlug: 'курорт' },
  { id: 41, slugList: ['завулок'], translateSlug: 'завулок' },
  { id: 42, slugList: ['автошлях'], translateSlug: 'автошлях' },
  { id: 43, slugList: ['балка'], translateSlug: 'балка' },
  { id: 44, slugList: ['хутір'], translateSlug: 'балка' },
  { id: 45, slugList: ['садове тов'], translateSlug: 'садове тов' },
  { id: 46, slugList: ['масив'], translateSlug: 'масив' },
  { id: 47, slugList: ['присілок'], translateSlug: 'присілок' },
];

export const STREET_BOUNDS = ['point_of_interest', 'establishment', 'route', 'park', 'transit_station', 'bus_station'];
export const CORP_BOUNDS = [' корпус ', ' корпус', 'корпус ', 'корпус', ' Корпус ', ' Корпус', 'Корпус ', 'Корпус'];

export const DEFAULT_ADDRESS_STATE = {
  addressArea: '',
  addressStreetType: '',
  addressStreetName: '',
  addressBuilding: '',
  addressBuildingPart: '',
  addressCorp: '',
  addressApartment: '',
};
