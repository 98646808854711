import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { ApiService, StorageService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import ModalChangeTerminalsStatuses from './modalChangeTerminalsStatuses/modalChangeTerminalsStatuses';
import { ADDITIONAL_FIELDS, ALL_GRID_COLUMNS, NORMALIZE_FIELD_MAP } from './constants';
import GqlService from '../../components/grids/gql.service';
import CreateTerminalListService from '../createTerminal/createTerminalListService';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();

class GridCreateTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      visible: false,
      showDeleted: false,
      selectedItems: [],
      rowAmount: '50',
      pageValue: 1,
      filtersString: '',
      sortString: '',
      hasNextPage: false,
      hasPreviousPage: false,
      owners: [],
      allOwners: [],
    };
    this.currentArray = findArray('createTerminal');
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  // componentDidMount() {
  //   this.fetchTerminalListGQL();
  // }

  // async componentDidUpdate(prevProps, prevState) {
  //   const { filtersString, pageValue, sortString, rowAmount } = this.state;
  //   const {
  //     filtersString: prevFiltersString,
  //     pageValue: prevPageValue,
  //     sortString: prevSortString,
  //     rowAmount: prevRowAmont,
  //   } = prevState;
  //
  //   if (
  //     pageValue !== prevPageValue ||
  //     sortString !== prevSortString ||
  //     filtersString !== prevFiltersString ||
  //     prevRowAmont !== rowAmount
  //   ) {
  //     if (filtersString === prevFiltersString) {
  //       await this.setState(({ selectedItems, defaultSelectedItems, data }) => {
  //         // console.log('selectedItems', selectedItems);
  //         const dataIds = data.map(({ TerminalId }) => TerminalId);
  //         const selectedItemsIds = selectedItems.map(({ TerminalId }) => TerminalId);
  //         const filteredDefaultSelectItems = defaultSelectedItems.filter(
  //           (id) => !dataIds.includes(id) || selectedItemsIds.includes(id),
  //         );
  //
  //         console.log('filteredDefaultSelectItems', filteredDefaultSelectItems);
  //         console.log('selectedItemsIds', selectedItemsIds);
  //         console.log('res', [...new Set([...filteredDefaultSelectItems, ...selectedItemsIds])]);
  //
  //         return {
  //           defaultSelectedItems: [...new Set([...filteredDefaultSelectItems, ...selectedItemsIds])],
  //         };
  //       });
  //
  //       await this.fetchTerminalListGQL();
  //       this.setState({ isDefaultItemsUpdateFlag: true });
  //     } else {
  //       await this.setState({
  //         selectedItems: [],
  //         defaultSelectedItems: [],
  //         pageValue: 1,
  //       });
  //       await this.fetchTerminalListGQL();
  //     }
  //   }
  // }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchTerminalListGQL({ selectIdsString });
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchTerminalListGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };
  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchTerminalListGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  fetchTerminalListGQL = async (props) => {
    const { showDeleted, rowAmount, pageValue, filtersString, sortString, owners } = this.state;

    if (!owners) {
      return;
    }

    const currentFilterString =
      props && props.selectIdsString
        ? `{and:[${filtersString}, {terminalId:{in:[${props.selectIdsString}]}}]}`
        : filtersString;

    this.toggleLoader(true);
    try {
      const storageColumns = StorageService.getItem('createTerminal');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns
          ? [...GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP), ...ADDITIONAL_FIELDS]
          : ALL_GRID_COLUMNS);
      const argumentsString = `take:${rowAmount}, skip:${
        rowAmount * (pageValue - 1)
      }, withDeleted: ${showDeleted},order: ${
        sortString || '{ datePostNoUTC: DESC }'
      },where:{and:[{ownerId:{in:[${owners}]}}, ${currentFilterString}]}`;
      const query = `terminalList(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { terminalListService, hasPreviousPage, hasNextPage } = CreateTerminalListService.getGQLResponse(data);
      this.setState({ data: terminalListService, hasPreviousPage, hasNextPage });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.fetchTerminalListGQL);
  };

  toggleModal = () => {
    this.setState(({ visible }) => {
      return {
        visible: !visible,
      };
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchTerminalListGQL({
      currentFieldList:
        list && list.length
          ? [
              ...list
                .map((field) => NORMALIZE_FIELD_MAP.get(field) || lowerCaseFirstLetter(field))
                .filter((field) => field !== 'selected'),
              ...ADDITIONAL_FIELDS,
            ]
          : null,
    });
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems, allOwners, owners } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(allOwners)}
          selectItems={owners}
          onSave={this.onOwners}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          isGetDefaultSelectItemsFromStorage
        />
        {checkElement('createTerminal-btn-create', this.currentArray) && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={this.createNewTerminal}>
            {translate('grids.createTerminal.btn-create')}
          </Button>
        )}
        {checkElement('createTerminal-btn-change-status', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.toggleModal}
            disabled={!selectedItems.length}
          >
            {translate('grids.createTerminal.btn-change-status')}
          </Button>
        )}
        {checkElement('createTerminal-setSchedule', this.currentArray) && (
          <Button
            type="primary"
            style={{ marginTop: '10px' }}
            onClick={this.createNewShedule}
            disabled={!selectedItems.length}
          >
            {translate('page.contacts.label-schedule')}
          </Button>
        )}
      </Col>
    );
  };

  createNewTerminal = () => {
    tabService.addTab({
      type: 'newTerminal',
    });
  };

  createNewShedule = () => {
    const { selectedItems } = this.state;

    tabService.addTab({
      type: 'newShedule',
      selectItems: selectedItems.map(({ TerminalId }) => TerminalId),
    });
  };

  renderTerminalIdOnclick = (props) => {
    if (checkElement('createTerminal-terminalIdOnclick', this.currentArray))
      return tabService.addTab({ type: 'propDetails', dataItem: props.dataItem });
    else return null;
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  showRemoteCallback = () => {
    this.setState(
      ({ showDeleted }) => ({ showDeleted: !showDeleted }),
      () => {
        this.fetchTerminalListGQL();
      },
    );
  };

  render = () => {
    const {
      isLoading,
      data,
      visible,
      showDeleted,
      selectedItems,
      hasNextPage,
      hasPreviousPage,
      pageValue,
      selectIdsString,
    } = this.state;
    return (
      <>
        <Grid
          data={data}
          onRefresh={() => {
            this.fetchTerminalListGQL({ selectIdsString });
          }}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          selectedField="selected"
          // toggleLoader={this.toggleLoader}
          name="createTerminal"
          permissionName="createTerminal"
          fieldForSelection="TerminalId"
          showRemoteCallback={this.showRemoteCallback}
          isRemoteShow={showDeleted}
          onSelect={this.handleSelectionChange}
          pageChange={this.pageChange}
          handleRowAmountChange={this.handleRowAmountChange}
          pageValue={pageValue}
          setFiltersString={this.setFiltersString}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          setSortingString={this.setSortingString}
          onFieldsConfigChange={this.onFieldsConfigChange}
          normalizeGQLFieldsMap={NORMALIZE_FIELD_MAP}
          onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
          onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
          multiSelected
          isGQL
          isShowSpecificFilterByFieldName="TerminalId"
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn
            field="TerminalId"
            title="grids.createTerminal.column-terminalId"
            width="150"
            filter="numeric"
            onClick={(props) => this.renderTerminalIdOnclick(props)}
            // onClick={(props) => tabService.addTab({ type: 'propDetails', dataItem: props.dataItem })}
          />

          <GridColumn field="Name" title="grids.createTerminal.column-name" width="150" />

          <GridColumn field="FiscalNumber" title="grids.createTerminal.column-fiscalNumber" width="150" />

          <GridColumn field="Code" title="grids.createTerminal.column-certificateCode" width="150" />
          <GridColumn field="RegionName" title="grids.createTerminal.column-region" width="150" />

          <GridColumn field="CityName" title="grids.createTerminal.column-city" width="150" />

          <GridColumn field="TerminalAddress" title="grids.createTerminal.column-address" width="145" />

          <GridColumn field="DesignTypeName" title="grids.createTerminal.column-designType" width="150" />

          <GridColumn field="CommissionProfileName" title="grids.createTerminal.column-profileComissions" width="145" />

          <GridColumn
            field="MainProfileId"
            title="grids.profilesMainProfileList.column-profileId"
            width="200"
            filter="numeric"
          />

          <GridColumn field="MainProfileName" title="grids.createTerminal.column-profileScreen" width="150" />

          <GridColumn field="StatusName" title="grids.createTerminal.column-status" width="150" />

          <GridColumn field="ProjectName" title="grids.createTerminal.column-project" width="150" />

          <GridColumn field="NetworkContractName" title="grids.createTerminal.column-networkContract" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.createTerminal.column-dateCreation"
            width="150"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn field="Branch" title="grids.terminals.column-branch" width="145" />

          <GridColumn field="Note" title="page.terminalSettings.field-notes" width="200" />
        </Grid>
        {visible && (
          <ModalChangeTerminalsStatuses
            visible={visible}
            closeModal={this.closeModal}
            data={selectedItems}
            update={this.fetchTerminalListGQL}
          />
        )}
      </>
    );
  };
}

export default withTranslate(GridCreateTerminal);
