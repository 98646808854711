import React, { PureComponent } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import Pages from './pages';
import PageLogin from './pages/login';
import FormStateContainer from './components/FormStateContainer/FormStateContainer';
import ExpiredPasswordInfoModal from './components/ExpiredPasswordInfoModal/ExpiredPasswordInfoModal';
import LoadingPanel from './components/loader';
import { widthExpiredPasswordDialogContext } from './contexts/ExpiredPasswordDialogContext';
import ExpiredPasswordFormModal from './components/ExpiredPasswordFormModal';
import { ApiService, StorageService } from './services';

const api = new ApiService();

class AppRouting extends PureComponent {
  state = {
    isLoading: false,
    getTokenReady: false,
    isAppAccessResolved: false,
  };

  setIsLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  componentDidMount() {
    // const { history } = this.props;
    this.resolveAppSuccess();
    // if (
    //   process.env.REACT_APP_LAST_UPDATE &&
    //   process.env.REACT_APP_LAST_UPDATE !== StorageService.getItem('globalLastUpdate')
    // ) {
    //   StorageService.destroy();
    //   StorageService.setItem('globalLastUpdate', process.env.REACT_APP_LAST_UPDATE || null);
    //   history.push('/login');
    // } else {
    //   StorageService.setItem('globalLastUpdate', process.env.REACT_APP_LAST_UPDATE || null);
    // }
  }

  authGuard = ({ location }) => {
    const { pathname } = location;
    // console.log(`App route ${pathname}`);

    // const token = StorageService.getItem('access_token');
    // if (token === null) {
    //   /* сюда добавить проверку и код
    //     для того чтобы проверять наличие DeviceCode
    //     В случае наличии его в Storage и успешного
    //     выполнения запроса на получение токена
    //     не выбрасывать пользователя на Логин
    //   */
    //   return <Redirect to="/login" />;
    // }

    // const currentUserInfo = StorageService.getItem('userInfo');
    // if (currentUserInfo && currentUserInfo.userPermissionsList.length === 1
    //   && currentUserInfo.userPermissionsList[0].webTag === 'tech-inform'
    //   && !pathname.startsWith('/pages')) {
    //   return (<Redirect to="/pages/tech-inform" />);
    // }

    if (!pathname.startsWith('/pages') && !pathname.startsWith('/login')) {
      console.log(`Wrong route ${pathname}`);
      return <Redirect to="/pages" />;
    }
  };

  resolveAppSuccess = async () => {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;

    if (pathname === '/login') {
      this.setState({ isAppAccessResolved: true });

      return;
    }

    try {
      await api.authorizeCheck();
      this.setState({ isAppAccessResolved: true });
    } catch (e) {
      const { history } = this.props;
      history.push('/login');
      this.setState({ isAppAccessResolved: true });
    }
  };

  render() {
    const { numberDraysBeforePasswordExpires, setNumberDraysBeforePasswordExpires, history, children, dialogStuff } =
      this.props;
    const { isLoading, isAppAccessResolved } = this.state;

    const { search } = history.location;

    const login = new URLSearchParams(search || '').getAll('login')[0];
    const oldPassword = search.split('oldPassword=')[1];

    if (!isAppAccessResolved) {
      return (
        <>
          <LoadingPanel />
        </>
      );
    }

    return (
      <>
        {children}
        {/* <Route render={this.authGuard} /> */}
        <Route path="/login" component={PageLogin} />
        <Route render={this.authGuard} />
        <Route path="/pages" component={Pages} />

        {(numberDraysBeforePasswordExpires || numberDraysBeforePasswordExpires === 0) && (
          <ExpiredPasswordInfoModal
            dialogStuff={dialogStuff}
            numberDraysBeforePasswordExpires={numberDraysBeforePasswordExpires}
            history={history}
            onCloseModal={() => {
              setNumberDraysBeforePasswordExpires(null);
            }}
            setIsLoading={this.setIsLoading}
          />
        )}
        {!!login && (
          <FormStateContainer
            initialValues={{ oldPassword: oldPassword, newPassword: undefined, repeat: undefined, login: login }}
          >
            <ExpiredPasswordFormModal
              numberDraysBeforePasswordExpires={numberDraysBeforePasswordExpires}
              history={history}
              onCloseModal={() => {
                history.push(`${history.location.pathname}`);
              }}
              setIsLoading={this.setIsLoading}
            />
          </FormStateContainer>
        )}
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withRouter(widthExpiredPasswordDialogContext(AppRouting));
