import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Icon, Drawer, Dropdown, Row, Col, Typography, BackTop, Badge } from 'antd';

import { ApiService, StorageService } from '../services';
import PagesRouting from './pagesRouting';
import PageFooter from './footer';
import menus from './menus';
import './pages.scss';
import { withTranslate } from '../contexts/localContext';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

// уникальный ключ меню
const api = new ApiService();
const buildKey = (level, i) => `${level}_${i}`;

class Pages extends PureComponent {
  constructor(props) {
    super(props);

    if (StorageService.getItem('collapsed') !== null) {
      this.collapsed = StorageService.getItem('collapsed');
    }

    this.state = {
      collapsed: !this.collapsed ? this.collapsed : true,
      visible: false,
      pinMenu: this.collapsed,
      windowWidth: window.innerWidth,
    };

    if (StorageService.getItem('userInfo') !== null) {
      this.userPermissions = StorageService.getItem('userInfo').userPermissionsList;
    }
  }

  currentInterval = null;

  userPermissions = [];

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.startFetchNewMessagesCountIterator();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    if (this.currentInterval || this.currentInterval === 0) {
      clearInterval(this.currentInterval);
    }
  }

  startFetchNewMessagesCountIterator = () => {
    const { changeUnreadedCount } = this.props;
    this.currentInterval = setInterval(async () => {
      const newMessagesCount = StorageService.getItem('newMessagesCount');

      changeUnreadedCount(newMessagesCount || 0);
    }, 5000);
  };

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  toggleNavigator = () => {
    const { visible, collapsed } = this.state;
    if (window.innerWidth > 991) {
      this.setState({ visible: true, collapsed: !collapsed });
    } else {
      this.setState({ visible: !visible, collapsed: false });
    }
  };

  togglePinSider = () => {
    const { pinMenu } = this.state;
    this.setState({ pinMenu: !pinMenu }, () => {
      if (pinMenu) {
        StorageService.setItem('collapsed', false);
      } else {
        StorageService.setItem('collapsed', true);
      }
    });
  };

  userMenu = () => {
    const { translate } = this.props;
    return (
      <Menu style={{ padding: 5 }} className="user-dropdown">
        <Menu.Item>
          <Link to="/pages/profile">
            <Icon type="user" />
            <span style={{ marginLeft: '5px' }}>{translate('sidebar.profile')}</span>
          </Link>
        </Menu.Item>
        <Menu.Divider style={{ margin: '6px 0' }} />
        <Menu.Item onClick={this.logout}>
          <Icon type="logout" />
          <span style={{ marginLeft: '5px' }}>{translate('sidebar.logout')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  logout = () => {
    const { history } = this.props;
    StorageService.clean();
    // const deviceCode = StorageService.getItem('devideCode');
    // const isPermittedAutoLogin = StorageService.getItem('isPermittedAutoLogin');
    // if (deviceCode !== null && isPermittedAutoLogin !== null &&
    //   isPermittedAutoLogin === false) {
    //   StorageService.removeItem('devideCode');
    //   StorageService.removeItem('isPermittedAutoLogin');
    // }
    history.push('/login');
  };

  changeLocale = ({ key }) => {
    const { changeLocale } = this.props;
    changeLocale(key);
  };

  languageMenu = () => {
    const { translate } = this.props;
    return (
      <Menu onClick={this.changeLocale} style={{ padding: 5, width: '200px' }}>
        <h3 style={{ paddingLeft: '12px' }}>{translate('sidebar.choose')}</h3>
        <Menu.Divider style={{ margin: '6px 0' }} />
        <Menu.Item key="uk">{translate('sidebar.ua')}</Menu.Item>
        <Menu.Item key="ru">{translate('sidebar.ru')}</Menu.Item>
        <Menu.Item key="en">{translate('sidebar.en')}</Menu.Item>
      </Menu>
    );
  };

  getUserEmail = () => {
    const userInfo = StorageService.getItem('userInfo');
    if (userInfo) {
      return userInfo.Email;
    }
  };

  renderHeader = () => {
    const headerStyle = {
      background: '#fff',
      paddingLeft: '20px',
      paddingRight: '20px',
      boxShadow: '0 1px 4px rgba(0,21,41,.08)',
    };

    const iconStyle = {
      fontSize: '25px',
      cursor: 'pointer',
      marginLeft: '30px',
    };

    const userInfo = StorageService.getItem('userInfo');

    console.log('userInfo', userInfo);

    const { translate, unreadedCount } = this.props;
    return (
      <Header style={headerStyle}>
        <Row type="flex">
          <Col md={16} sm={8}>
            <Icon
              //className="trigger header-trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggleNavigator}
              style={{ fontSize: '21px', marginRight: '20px' }}
            />
            <Text className="header-menu-text" style={{ fontSize: '17px' }}>
              {translate('page.main.menu')}
            </Text>
          </Col>
          <Col md={8} sm={16}>
            {this.isPermited({ requirePermission: 'messages' }) && (
              <Link to="/pages/messages" className="notifications-link">
                <Badge
                  count={unreadedCount}
                  overflowCount={99}
                  // showZero
                >
                  <Icon
                    type="bell"
                    style={{
                      fontSize: '25px',
                    }}
                  />
                </Badge>
              </Link>
            )}

            <Dropdown overlay={this.userMenu} style={{ height: '100%' }} trigger={['click']}>
              <span>
                <Icon style={iconStyle} type="user" />
                <span className="trancated-user-name" style={{ marginLeft: '3px' }}>
                  {this.getUserEmail()}
                </span>
              </span>
            </Dropdown>
            <Dropdown overlay={this.languageMenu} style={{ height: '100%' }} trigger={['click']}>
              <Icon style={iconStyle} type="global" />
            </Dropdown>
          </Col>
        </Row>
      </Header>
    );
  };

  isPermited = (item) => {
    if (!this.userPermissions || this.userPermissions.length < 1) return false;

    return this.userPermissions.find(
      (perm) => item.requirePermission === perm.webTag || item.requirePermission === 'ignore',
    );
  };

  renderNavigatorItems = (items, level = 0) => {
    const { Item, SubMenu } = Menu;
    const { translate } = this.props;
    return items
      .filter((item) => {
        if (item.children) {
          return this.isPermited(item) && item.children.find((item) => this.isPermited(item));
        }

        return this.isPermited(item);
      })
      .map((item, i) => {
        // TODO secure check
        // создаем уникальный ключ столбца
        const key = buildKey(level, i);
        const isPermission = this.isPermited(item);
        if (item.children) {
          return (
            isPermission && (
              <SubMenu
                key={key}
                forceSubMenuRender
                theme="light"
                title={
                  <>
                    {/*<Icon type={item.icon} />*/}
                    <span>{this.sliceByAmount(translate(item.text))}</span>
                  </>
                }
              >
                {this.renderNavigatorItems(item.children, level + 1)}
              </SubMenu>
            )
          );
        }
        // isPermission && --> uncomment for secure filter
        return (
          isPermission && (
            <Item onClick={!this.state.pinMenu ? '' : this.toggleNavigator} key={item.route}>
              <span style={{ padding: ' 0 24px' }}>
                <Link to={item.route}>
                  {/*<Icon type={item.icon} />*/}
                  {this.sliceByAmount(translate(item.text))}
                </Link>
              </span>
            </Item>
          )
        );
      });
  };

  sliceByAmount = (string) => {
    const { windowWidth } = this.state;

    if (string.length > 26) {
      return `${windowWidth > 991 ? string.slice(0, 28).trim() : string.slice(0, 22).trim()}...`;
    }

    return string;
  };

  renderNavigator = () => {
    const { windowWidth, collapsed } = this.state;

    const {
      history: { location },
    } = this.props;
    const navigator = (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="page-sider"
        breakpoint="lg"
        collapsedWidth="0"
        width={320}
        style={{
          boxShadow: '2px 0 6px rgba(0,21,41,.35)',
          zIndex: '24',
        }}
      >
        <div className="header-logo-wrapper">
          <img className="header-logo" src="/logo-dark.svg" alt="EasyPay" />
        </div>

        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
          {this.renderNavigatorItems(menus)}
          <div className="collapsed-menu" onClick={this.togglePinSider}>{`${
            !this.state.pinMenu ? 'UNPIN' : 'PIN'
          } MENU`}</div>
        </Menu>
      </Sider>
    );

    if (windowWidth > 991) {
      return navigator;
    }

    return (
      <Drawer
        children={navigator}
        placement="left"
        closable={false}
        className="drawer-sider"
        visible={this.state.visible}
        onClose={this.toggleNavigator}
        width={256}
      />
    );
  };

  render() {
    return (
      <Layout className="page-layout">
        {this.renderNavigator()}
        <Layout>
          {this.renderHeader()}
          <Content
            style={{
              margin: '9px 9px 4px',
              padding: 0,
              background: '#fff',
            }}
          >
            <PagesRouting />
          </Content>
          <PageFooter />
        </Layout>
        <BackTop visibilityHeight={200} />
      </Layout>
    );
  }
}

export default withRouter(withTranslate(Pages));
