import React, { PureComponent } from 'react';
import { Col, Button, Row, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import Grid from '../../../components/grids/baseGrid';

import './RolesGrid.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class ReleasesMatrix extends PureComponent {
  state = {
    isLoading: false,
    roles: [],
    selectedItems: [],
    defaultSelectItems: [],
    roleForHide: [],
    roleForAdd: [],
  };

  currentArray = findArray('releasePage');

  componentDidMount = async () => {
    this.onInit();
  };

  onInit = async () => {
    await this.fetchRoles();
    await this.getCommonRoles();
  };

  onRefresh = async () => {
    await this.setState({ defaultSelectItems: [] });
    await this.onInit();
  };

  getCommonRoles = async () => {
    const { selectedElements, showError } = this.props;
    console.log('selectedElements', selectedElements);
    try {
      await this.setState({ isLoading: true });

      Promise.all(
        selectedElements.map(({ elementKey, elementType }) => api.UserRolesWtmElements(elementKey, elementType)),
      )
        .then((responses) => {
          const commonRoles = responses.reduce((acc, roles, index) => {
            return index
              ? acc.filter(({ RoleId: currentRoleId }) => roles.find(({ RoleId }) => RoleId === currentRoleId))
              : roles;
          }, []);

          this.setState({
            defaultSelectItems: commonRoles && commonRoles.length ? commonRoles.map(({ RoleId }) => RoleId) : [],
          });

          this.setState({ isLoading: false });
        })
        .catch((responses) => {
          console.log('ERROR', responses);
          showError(responses);
          this.setState({ isLoading: false });
        });
    } catch (e) {
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  fetchRoles = async () => {
    try {
      this.setState({ isLoading: true });
      const roles = await api.getAllRoles();
      this.setState({ roles: roles && roles.length ? roles : [] });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async () => {
    const { translate } = this.props;
    const { roleForHide, roleForAdd } = this.state;

    await this.setRolesForElements(roleForHide, 0);
    await this.setRolesForElements(roleForAdd, 1);
    await this.setState({ defaultSelectItems: [] }, async () => {
      await this.onInit();
      await message.success(translate('page.releases.selectedItemsSuccessfullyAssociated'), 2);
    });
  };

  getModelByGrantType = (roleList, grantType) => {
    const { selectedElements } = this.props;

    if (grantType) {
      return roleList.reduce(
        (acc, roleId) => [
          ...acc,
          ...selectedElements.map(({ elementKey, elementType }) => ({ elementKey, elementType, roleId, grantType })),
          // ...new Set(
          //   selectedElements.map(({ controlTag }) => ({
          //     elementKey: controlTag,
          //     elementType: 0,
          //     roleId,
          //     grantType,
          //   })),
          // ),
        ],
        [],
      );
    }

    return roleList.reduce(
      (acc, roleId) => [
        ...acc,
        ...selectedElements.map(({ elementKey, elementType }) => ({ elementKey, elementType, roleId, grantType })),
      ],
      [],
    );
  };

  setRolesForElements = async (roleList, grantType) => {
    try {
      this.setState({ isLoading: true });

      if (!roleList.length) {
        return;
      }

      const models = this.getModelByGrantType(roleList, grantType);

      await Promise.all(models.map((model) => api.userRolesWtmElementMerge(model)));
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { roleForHide, roleForAdd, selectedItems } = this.state;
    console.log(roleForHide, roleForAdd, selectedItems);
    const isMasterRoleNotChosen = !selectedItems.find(({ RoleId }) => RoleId === 1);
    return (
      <Row className="RolesGrid-toolbar">
        <Col span={16}>
          {checkElement('releasePage-associateSelectedRoles', this.currentArray) && (
            <Button
              type="primary"
              onClick={this.handleSubmit}
              disabled={(!roleForHide.length && !roleForAdd.length) || isMasterRoleNotChosen}
            >
              {translate('page.releases.associateSelectedRoles')}
            </Button>
          )}
          {isMasterRoleNotChosen && (
            <div className="RolesGrid-errorTitle">{translate('page.releases.supervisorError')}</div>
          )}
        </Col>
        <Col span={24} className="RolesGrid-warning">
          <div className="RolesGrid-warningTitle">{translate('page.releases.note')}</div>
          <div>
            <span className="RolesGrid-highlightedText">{translate('page.releases.removingCheckbox')}</span>{' '}
            <span>{translate('page.releases.fromAnyRole')}</span>{' '}
            <span className="RolesGrid-highlightedText">{translate('page.releases.willBeApplied')}</span>{' '}
            <span>{translate('page.releases.previouslySelectedElements')}</span>{' '}
            <span className="RolesGrid-highlightedText">{translate('page.releases.withSuchRole')}</span>
          </div>
        </Col>
      </Row>
    );
  };

  handleSelectionChange = (selectedItems) => {
    const { defaultSelectItems } = this.state;

    const roleForHide = defaultSelectItems.filter(
      (defaultRoleId) => !selectedItems.find(({ RoleId }) => defaultRoleId === RoleId),
    );

    const roleForAdd = selectedItems
      .filter(({ RoleId: selectedRoleId }) => !defaultSelectItems.find((RoleId) => selectedRoleId === RoleId))
      .map(({ RoleId }) => RoleId);

    this.setState({ selectedItems, roleForHide, roleForAdd });
  };

  rowRender = (element, { dataItem: { RoleId } }) => {
    const { roleForHide, roleForAdd } = this.state;
    const { children, className } = element.props;
    const isTouched = roleForHide.includes(RoleId) || roleForAdd.includes(RoleId);

    return React.cloneElement(
      element,
      {
        className: `${className} ${isTouched ? 'RolesGrid--touchedRow' : ''}`,
      },
      children,
    );
  };

  updateDefaultSelectItemsCallback = ({ defaultSelectItems, prevDefaultSelectItems }) =>
    defaultSelectItems && defaultSelectItems.length !== prevDefaultSelectItems.length;

  render() {
    const { roles, defaultSelectItems, isLoading } = this.state;

    return (
      <Grid
        className="RolesGrid"
        data={roles}
        defaultSelectItems={defaultSelectItems}
        onRefresh={this.onRefresh}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="userRolesGrid"
        multiSelected
        fieldForSelection="RoleId"
        onSelect={this.handleSelectionChange}
        updateDefaultSelectItemsCallback={this.updateDefaultSelectItemsCallback}
        rowRender={this.rowRender}
      >
        <GridColumn
          field="selected"
          width="50px"
          filterable={false}
          sortable={false}
          columnMenu={false}
          showAllSelected
        />
        <GridColumn field="RoleId" title="page.releases.col-roleNumber" width="100" />
        <GridColumn field="RoleName" title="page.releases.col-roleName" width="180" />
        <GridColumn field="ApplicationName" title="page.releases.col-source" width="100" />
        <GridColumn field="RoleDescription" title="page.releases.col-roleDescription" width="900" />
      </Grid>
    );
  }
}

export default withTranslate(ReleasesMatrix);
