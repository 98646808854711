import React, { PureComponent } from 'react';
import { Alert, Button, message, Modal } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../loader';
import TerminalRegistrationForm from './TerminalRegistrationForm';
import FormStateContainer from '../../FormStateContainer';
import { PRRO_PAYMENT_SYSTEM_NAME } from './constants';
import { REQUIRED_FIELD as EXPENDED_ADDRESS_FIELDS } from '../../ExtendedAddressModal/contants';

import './ModalRegistration.styled.scss';

const api = new ApiService();

class ModalRegistration extends PureComponent {
  state = {
    isLoading: false,
  };

  onRegistrationSubmit = async () => {
    const { globalData, onClose, translate, formState } = this.props;

    try {
      await this.setState({ isLoading: true });
      const model = {
        typeCodeNew: 715,
        name: `ПТКС ${globalData.terminalId}`,
        idNew: globalData.businessUnit,
        address1: globalData.currentRegionId.CityName,
        address2: globalData.addressValue,
        prroName: `ПТКС ${globalData.terminalId}`,
        prroId: globalData.terminalId,
        prroPaymentSystemName: PRRO_PAYMENT_SYSTEM_NAME,
        katottg: formState.katottg,
        addressStreetType: formState.addressStreetType,
        addressStreetName: formState.addressStreetName,
        addressBuilding: formState.addressBuilding,
        addressBuildingPart: formState.addressBuildingPart,
        addressCorp: formState.addressCorp,
        addressApartment: formState.addressApartment,
      };

      await api.terminalRegistrationApplication(model);
      await message.success(`${translate('page.terminalSettings.mgs-success')}`, 2.5);
      await onClose();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onDeregistrationSubmit = async () => {
    const { globalData, onClose, translate, formState, fiscalNumber } = this.props;

    try {
      await this.setState({ isLoading: true });
      const model = {
        typeCodeOld: 715,
        name: `ПТКС ${globalData.terminalId}`,
        idOld: globalData.businessUnit,
        address1: globalData.currentRegionId.CityName,
        address2: globalData.addressValue,
        prroName: `ПТКС ${globalData.terminalId}`,
        prroNumber: globalData.fiscalNumber ? globalData.fiscalNumber : fiscalNumber,
        prroFiscalNumberOld: globalData.fiscalNumber ? globalData.fiscalNumber : fiscalNumber,
        prroId: globalData.terminalId,
        prroPaymentSystemName: null,
        katottg: formState.katottg,
        addressStreetType: formState.addressStreetType,
        addressStreetName: formState.addressStreetName,
        addressBuilding: formState.addressBuilding,
        addressBuildingPart: formState.addressBuildingPart,
        addressCorp: formState.addressCorp,
        addressApartment: formState.addressApartment,
      };

      await api.terminalUnregistrationApplication(model);
      await message.success(`${translate('page.terminalSettings.mgs-success')}`, 2.5);
      await onClose();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  render() {
    const { visible, translate, onClose, modalName, routes, formState, onChangeFormState, formErrors } = this.props;
    const { isLoading } = this.state;

    const isSubmitDisabled = Object.entries(formState)
      .filter(([key, _]) => [...EXPENDED_ADDRESS_FIELDS, 'katottg'].includes(key))
      .some(([_, value]) => !value);

    return (
      <>
        <Modal
          className="TerminalRegistrationManagementModal"
          title={translate(
            modalName === 'terminalRegistration'
              ? 'page.terminalSettings.modal-registration'
              : 'page.terminalSettings.modal-deregistration',
          )}
          visible={visible}
          onCancel={onClose}
          footer={[
            <Button
              type="primary"
              onClick={modalName === 'terminalRegistration' ? this.onRegistrationSubmit : this.onDeregistrationSubmit}
              disabled={isSubmitDisabled}
            >
              {translate(
                modalName === 'terminalRegistration'
                  ? 'core.buttonTitles.request-registration'
                  : 'core.buttonTitles.request-unRegistration',
              )}
            </Button>,
            <Button onClick={onClose}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
          key="ModalRegistration"
          name="ModalRegistration"
        >
          <>
            <div className="TerminalRegistrationManagementModal-alert">
              <Alert message={translate('page.terminalSettings.address-warning-message')} type="warning" showIcon />
            </div>

            <TerminalRegistrationForm
              streetRoutes={routes}
              formState={formState}
              onChangeFormState={onChangeFormState}
              formErrors={formErrors}
            />
          </>
        </Modal>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

class ModalRegistrationWrapper extends PureComponent {
  getInitialValues = () => {
    const {
      formState: {
        addressStreetType,
        addressStreetName,
        addressBuilding,
        addressBuildingPart,
        addressCorp,
        addressApartment,
      },
    } = this.props;

    return {
      addressStreetType,
      addressStreetName,
      addressBuilding,
      addressBuildingPart,
      addressCorp,
      addressApartment,
      katottg: '',
    };
  };

  render() {
    const { formState: globalData, ...restProps } = this.props;

    return (
      <FormStateContainer initialValues={this.getInitialValues()}>
        <ModalRegistration globalData={globalData} {...restProps} />
      </FormStateContainer>
    );
  }
}

export default withTranslate(ModalRegistrationWrapper);
