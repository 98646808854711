export const clientCabinetFinmon = (translate, value) => {
  switch (value) {
    case 'clientCabinetFinmon-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'clientCabinetFinmon-сhangeClientData':
      return `${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.changeClientData`)}`;
    case 'clientCabinetFinmon-downloadQuestionnaire':
      return `${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.downloadQuestionnaire`)}`;
    case 'clientCabinetFinmon-clientDocuments':
      return `${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.clientDocuments`)}`;
    case 'clientCabinetFinmon-confirmQuestionnaire':
      return `${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.confirmQuestionnaire`)}`;
    case 'clientCabinetFinmon-confirmDocuments':
      return `${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.confirmDocuments`)}`;
    case 'clientCabinetFinmon-operationHistory':
      return `${translate('page.adminPanel.type-navLink')}
                                  ${translate('page.clientCabinet.operationHistory')}`;
    case 'clientCabinetFinmon-operationHistoryTransactionDetails':
      return `${translate('page.adminPanel.type-tab')} ${translate('page.clientCabinet.operationHistory')}, ${translate(
        'page.adminPanel.type-navLink',
      )}: ${translate('grids.transactionDeatails.transaction')}`;
    case 'clientCabinetFinmon-questionnaireProfile':
      return `${translate(`page.adminPanel.type-button`)} ${translate(
        `page.clientCabinet.userQuestionnaire`,
      )} / ${translate(`page.adminPanel.type-button`)} ${translate(`page.clientCabinet.downloadUserQuestionnaire`)}`;
    case 'clientCabinetFinmon-questionnaireProfileEdit':
      return `${translate('page.adminPanel.type-tab')} ${translate(
        'page.clientCabinet.userQuestionnaire',
      )}, ${translate('page.adminPanel.type-button')} ${translate('core.buttonTitles.edit')}`;
  }
};
