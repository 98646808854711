import { process } from '@progress/kendo-data-query';
import { formatDateNumber } from '../../../utils/formatDateNumber';

class DesktopService {
  getOnlyDate = (date) =>
    !date || new Date(date).getFullYear() === 1970
      ? ''
      : `${formatDateNumber(new Date(date).getDate())}.${formatDateNumber(new Date(date).getMonth() + 1)}.${new Date(
          date,
        ).getFullYear()}`;

  customGrouping = (data, dataStateRender) => {
    if (dataStateRender.group.find(({ filter }) => filter === 'date')) {
      const dataStateDateFields = dataStateRender.group
        .filter(({ filter }) => filter === 'date')
        .map(({ field }) => field);

      const dataWithCustomGroupingFields = data.map((item) => {
        const customGroupingFields = Object.entries(item).reduce(
          (acc, [key, value]) =>
            dataStateDateFields.includes(key) ? { ...acc, [`customGroupField--${key}`]: this.getOnlyDate(value) } : acc,
          {},
        );

        return { ...item, ...customGroupingFields };
      });

      const dataStateRenderWithCustomGroupingFields = {
        ...dataStateRender,
        group: dataStateRender.group.map(({ field, order }) =>
          dataStateDateFields.includes(field)
            ? { field: `customGroupField--${field}`, order, aliasedField: field }
            : { field, order },
        ),
      };

      return process(dataWithCustomGroupingFields, dataStateRenderWithCustomGroupingFields).data;
    }

    return process(data, dataStateRender).data;
  };

  getAliasedFieldName = (fieldName) =>
    fieldName.includes('customGroupField--') ? fieldName.split('customGroupField--')[1] : fieldName;
}

export default new DesktopService();
