import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';

class QuestionnaireSignDownloadRequestModal extends PureComponent {
  downloadUserQuestionnaireWithSign = async () => {
    const { downloadUserQuestionnaireWithSign, downloadUserQuestionnaireFile, closeModal } = this.props;

    closeModal();
    await downloadUserQuestionnaireFile();
    await downloadUserQuestionnaireWithSign();
  };

  handleClose = () => {
    const { closeModal, downloadUserQuestionnaireFile } = this.props;

    closeModal();
    downloadUserQuestionnaireFile();
  };

  render() {
    const { translate, visible, closeModal } = this.props;

    return (
      <Modal
        title={translate('page.clientCabinet.questionnaireSignDownloadRequestModalTitle')}
        visible={visible}
        onOk={this.handleOk}
        onCancel={closeModal}
        footer={[
          <Button key="submit" type="primary" onClick={this.downloadUserQuestionnaireWithSign}>
            {translate('core.buttonTitles.yes')}
          </Button>,
          <Button key="back" onClick={this.handleClose}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        centered
      >
        {translate('page.clientCabinet.questionnaireSignDownloadRequestModalContent')}
      </Modal>
    );
  }
}

export default withTranslate(QuestionnaireSignDownloadRequestModal);
