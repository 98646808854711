import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Row } from 'antd';

import Grid from '../../components/grids/baseGrid';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import { ApiService } from '../../services';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { USER_COL_CONFIG } from '../../core/constants/configs.const';
import { withTranslate } from '../../contexts/localContext';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class ServiceUserAccessHistGrid extends PureComponent {
  state = {
    hist: [],
    isLoading: false,
    selectOwners: [],
    owners: [],
    selectedUsers: [],
    users: [],
  };

  componentDidMount() {
    this.fetchOwners();
  }

  searchHistory = async () => {
    const { selectedUsers } = this.state;

    if (!selectedUsers.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const data = await api.getServicesAccessHistList({
        userId: selectedUsers[0],
      });

      await this.setState({
        hist: data.map(({ DatePostFrom, DatePostTo, ...restItems }) => ({
          ...restItems,
          DatePostFrom: DatePostFrom ? getDataByTimezoneOffset(DatePostFrom).toISOString() : null,
          DatePostTo: DatePostTo ? getDataByTimezoneOffset(DatePostTo).toISOString() : null,
        })),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const data = await api.owners();

      await this.setState({ owners: DropdownNormalizersService.normalizeOwners(data), isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchUsersList = async () => {
    if (!this.state.selectOwners && !this.state.selectOwners.length) return;

    try {
      await this.setState({ isLoading: true });
      const userList = await api.postGetUsersList(this.state.selectOwners);
      this.setState({
        users: userList && userList.length ? userList.map((user) => ({ ...user, Id: user.UserId })) : [],
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  handleSelectChange = (selectOwners) => {
    this.setState({ selectOwners, selectedUsers: [] }, () => {
      this.fetchUsersList();
    });
  };

  buildToolbar = () => {
    const { users, selectOwners, owners, selectedUsers } = this.state;
    const { translate } = this.props;

    return (
      <Row className="ServiceUserAccessGrid-toolbar">
        <div className="ServiceUserAccessGrid-fieldContent">
          <div className="ServiceUserAccessGrid-fieldRow">
            <div className="ServiceUserAccessGrid-label">{translate('page.commissionsServices.dealer')}</div>
            <div className="ServiceUserAccessGrid-select">
              <GridDropdown
                data={owners}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={selectOwners}
                onSave={this.handleSelectChange}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
              />
            </div>
          </div>

          <div className="ServiceUserAccessGrid-fieldRow">
            <div className="ServiceUserAccessGrid-longLabel">{translate('page.serviceUserAccess.user')}</div>
            <div className="ServiceUserAccessGrid-select">
              <GridDropdown
                data={users}
                colConfig={USER_COL_CONFIG}
                selectItems={selectedUsers}
                onSave={(selectedUsers) => {
                  this.setState({ selectedUsers });
                }}
                defaultTitle={translate('page.serviceUserAccess.selectUser')}
                disabled={!selectOwners.length}
                isSingle
              />
            </div>
          </div>
        </div>

        <div className="ServiceUserAccessGrid-searchAction">
          <Button type="primary" disabled={!selectedUsers.length} onClick={this.searchHistory}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>
      </Row>
    );
  };

  render() {
    const { hist, isLoading } = this.state;

    return (
      <Grid
        data={hist}
        isLoading={isLoading}
        toolbar={this.buildToolbar()}
        name="serviceUserAccessHistGrid"
        onRefresh={this.searchHistory}
      >
        <GridColumn field="ServiceId" title="grids.servicesBigTable.column-serviceId" width="120" filter="numeric" />
        <GridColumn field="ServiceName" title="page.paymentTypeNBUCode.col-serviceName" width="300" />
        <GridColumn field="UserIdPost" title="page.serviceUserAccess.performerId" width="130" />
        <GridColumn
          field="DatePostFrom"
          title="page.serviceUserAccess.dateStart"
          width="160"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn
          field="DatePostTo"
          title="page.serviceUserAccess.dateEnd"
          width="160"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(ServiceUserAccessHistGrid);
