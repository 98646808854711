import React, { Component } from 'react';
import Geocode from 'react-geocode';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import { withTranslate } from '../../contexts/localContext';
import GoogleMapService from './googleMap.service';

Geocode.setApiKey('AIzaSyBYB11ozlIxbbsqAwvWfBIuCY3U7nsSoeQ');
Geocode.enableDebug();
Geocode.setLanguage('uk');

const _ = require('lodash');
const { compose, withProps, lifecycle } = require('recompose');
const { SearchBox } = require('react-google-maps/lib/components/places/SearchBox');

const getInitCoordinates = async (currentGeopoint, cityName, showError) => {
  try {
    if (typeof currentGeopoint === 'string' && currentGeopoint) {
      return { lat: +currentGeopoint.split(',')[0], lng: +currentGeopoint.split(',')[1] };
    }

    const response = await Geocode.fromAddress(cityName);
    const defaultBounds = await response.results[0].geometry.location;
    const lat = defaultBounds.lat;
    const lng = defaultBounds.lng;

    return { lat: lat || '', lng: lng || '' };
  } catch (error) {
    showError(error);
  }
};

// AIzaSyASXcFrGBfL7Hqc4-qB50xvKfL1zGIFvoM
const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBYB11ozlIxbbsqAwvWfBIuCY3U7nsSoeQ&v=3.exp&libraries=geometry,drawing,places&language=uk',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    async componentWillMount() {
      const refs = {};
      const { cityName, showError, currentGeopoint } = this.props;
      const initCenter = await getInitCoordinates(currentGeopoint, cityName, showError);

      this.setState({
        bounds: null,
        center: initCenter,
        zoom: 15,
        markers: [
          {
            position: initCenter,
          },
        ],
        places: [],
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onMapClick: async (e) => {
          const obj = JSON.parse(JSON.stringify(e.latLng));
          const strForGeopointState = `${obj.lat},${obj.lng}`;
          const { onChange, onChangeGeopoint } = this.props;
          const decodedPlaces = await Geocode.fromLatLng(obj.lat, obj.lng);
          this.setState(
            {
              markers: [{ position: e.latLng }],
              bounds: null,
              center: e.latLng,
              places: [decodedPlaces.results[0]],
            },
            () => {
              if (decodedPlaces && decodedPlaces.results && decodedPlaces.results[0]) {
                onChange(GoogleMapService.parseAddress(decodedPlaces));
                onChangeGeopoint(strForGeopointState);
              }
            },
          );
        },
        onBoundsChanged: async () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
            zoom: 15,
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: async (param = undefined) => {
          const { onChange, onChangeGeopoint } = this.props;
          let places;
          let nextMarkers;
          const bounds = new window.google.maps.LatLngBounds();
          if (param !== undefined) {
            places = param['formatted_address'];
          } else {
            places = refs.searchBox.getPlaces();
            places.forEach((place) => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            nextMarkers = places.map((place) => ({
              position: place.geometry.location,
            }));
          }
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState(
            {
              center: nextCenter,
              markers: nextMarkers,
              places,
              zoom: 15,
            },
            () => {
              if (places && places !== undefined && places[0] !== undefined && places[0]['formatted_address']) {
                onChange(GoogleMapService.parseAddress({ results: places }));
                const strForGeopointState = `${places[0].geometry.location.lat()},${places[0].geometry.location.lng()}`;
                onChangeGeopoint(strForGeopointState);
              }
            },
          );
          refs.map.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const { translate } = props;
  return (
    <>
      <GoogleMap
        ref={props.onMapMounted}
        zoom={props.zoom}
        center={props.center}
        onIdle={props.onBoundsChanged}
        onClick={props.onMapClick}
      >
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder={translate('page.terminalSettings.modal-map-search')}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `27px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              left: '30px',
              top: '33px',
            }}
          />
        </SearchBox>
        {props.markers &&
          props.markers.map((marker, index) => {
            return <Marker key={index} position={marker.position} />;
          })}
      </GoogleMap>
      <ol>
        {props.places
          ? props.places.map(({ place_id, formatted_address }) => <li key={place_id}>{formatted_address}</li>)
          : ''}
      </ol>
    </>
  );
});

class MapContainer extends Component {
  componentDidMount() {}

  render() {
    const { onChange, onChangeGeopoint, showError, translate, cityName, currentGeopoint } = this.props;

    return (
      <MapWithASearchBox
        cityName={cityName}
        onChange={onChange}
        onChangeGeopoint={onChangeGeopoint}
        currentGeopoint={currentGeopoint}
        showError={showError}
        translate={translate}
      />
    );
  }
}

export default withTranslate(MapContainer);
