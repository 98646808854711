import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import PageTabs from '../../components/tabber/tabber';
import {
  GridTransactionDetail,
  GridTransactionWithAdditionalProps,
  AdditionalTransactionInfo,
} from '../../components/grids';

import GridTransactions from './FinancialTransactionGrid';

class PageTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page.dashboard.tab-view-transactions',
          content: <GridTransactions />,
          closable: false,
        },
      ],
    };
  }

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;
    switch (type) {
      case 'details':
        tabs.push({
          title: `${translate('page.dashboard.tab-transaction-details')}`,
          content: <GridTransactionDetail dataItem={dataItem} />,
          closable: true,
          key,
        });
        break;
      case 'additionalProps':
        tabs.push({
          title: `${translate('page.transactions.transactionWithAdditionalProps')}`,
          content: <GridTransactionWithAdditionalProps dataItem={dataItem} />,
          closable: true,
          key,
        });
        break;
      case 'additionalInfo':
        tabs.push({
          title: `${translate('page.transactions.additionalInfoFor')} ${dataItem.transactionId}`,
          content: <AdditionalTransactionInfo dataItem={dataItem} />,
          closable: true,
          key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(PageTransactions);
