import React, { PureComponent } from 'react';
import { Button, Col, message, Row } from 'antd';

import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { OWNERS_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

import './CashDebtByCollectorsGrid.scss';
import CashDebtByCollectorsService from './CashDebtByCollectors.service';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';
import { getCustomDate } from '../../../utils/getCustomDate';

const api = new ApiService();

export const CashDebtByCollectors_MIN_VALID_DATE = getCustomDate({
  customTime: { h: 0, m: 0, s: 0, ms: 0 },
  customDayOfMonth: 1,
  customDateMonth: 0,
  customDateFullYear: 2024,
});

class CashDebtByCollectorsGrid extends PureComponent {
  currentArray = findArray('cashDebtByCollectors');

  state = {
    cashDebtData: [],
    isLoading: false,
    selectOwners: [],
    owners: [],
    dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
    dateEnd: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999),
    errorMessage: '',
  };

  fetchOwners = async () => {
    this.setState({ isLoading: true });
    try {
      const owners = await api.owners();
      const isOwnersExist = owners && owners.length;
      this.setState({
        owners: isOwnersExist ? DropdownNormalizersService.normalizeOwners(owners) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onDropdownSave = (selectOwners) => {
    this.setState({ selectOwners, cashDebtData: [] });
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
        cashDebtData: [],
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: CashDebtByCollectorsService.getSubmitErrorsByDate(
            StandardizeTimeForDate(dateStart, true),
            StandardizeTimeForDate(dateEnd),
            translate,
          ),
        }));
      },
    );
  };

  getFormatDate = (number) => (number > 9 ? number : `0${number}`);

  searchCollectors = async () => {
    const { translate } = this.props;
    const { selectOwners, dateStart, dateEnd, errorMessage } = this.state;

    if (dateStart < CashDebtByCollectors_MIN_VALID_DATE) {
      this.setState({ errorMessage: translate('page.cashDept.minDateError') });

      message.error(translate('page.cashDept.minDateError'), 2);

      return;
    }

    if (errorMessage) {
      this.setState({ isLoading: false });
      return;
    }
    this.setState({ isLoading: true });


    try {
      const model = {
        owners: selectOwners[0],
        dateStart: `${dateStart.getFullYear()}${this.getFormatDate(dateStart.getMonth() + 1)}${this.getFormatDate(
          dateStart.getDate(),
        )}`,
        dateEnd: `${dateEnd.getFullYear()}${this.getFormatDate(dateEnd.getMonth() + 1)}${this.getFormatDate(
          dateEnd.getDate(),
        )}`,
      };

      const collectorsData = await api.getCashDebtByCollectors(model);

      if (!collectorsData) {
        this.setState({ isLoading: false });
        return;
      }

      this.setState({
        cashDebtData:
          collectorsData.Report && collectorsData.Report.length
            ? CashDebtByCollectorsService.normalizeСashDebtData(collectorsData.Report)
            : [],
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { selectOwners, owners, dateStart, dateEnd, errorMessage } = this.state;
    const { translate } = this.props;

    return (
      <Row className="CashDebtByCollectorsGrid-toolbar">
        <Col>
          <Col className="CashDebtByCollectorsGrid-selectRow">
            <GridDropdown
              data={owners}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={selectOwners}
              onSave={this.onDropdownSave}
              isSingle
              isGetDefaultSelectItemsFromStorage
            />
          </Col>

          <div className="CashDebtByCollectorsGrid-datePickersRow">
            <div className="CashDebtByCollectorsGrid-datePickerRow">
              <div className="CashDebtByCollectorsGrid-label">{translate('page.changingProfileHistory.dateFrom')}</div>
              <DatePicker
                format="yyyy.MM.dd"
                className="CashDebtByCollectorsGrid-datePicker"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={CashDebtByCollectors_MIN_VALID_DATE}
              />
            </div>

            <div className="CashDebtByCollectorsGrid-datePickerRow">
              <div className="CashDebtByCollectorsGrid-label">{translate('page.changingProfileHistory.dateTo')}</div>
              <DatePicker
                format="yyyy.MM.dd"
                className="CashDebtByCollectorsGrid-datePicker"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={CashDebtByCollectors_MIN_VALID_DATE}
              />
            </div>
          </div>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={this.searchCollectors}
            disabled={!!errorMessage || !selectOwners.length}
            className="CashDebtByCollectorsGrid-button"
          >
            {translate('core.buttonTitles.search')}
          </Button>
        </Col>

        {errorMessage && <div className="CashDebtByCollectorsGrid-error">{errorMessage}</div>}
      </Row>
    );
  };

  async componentDidMount() {
    await this.fetchOwners();
  }

  render() {
    const { cashDebtData, isLoading } = this.state;

    if (!checkElement('cashDebtByCollectors-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={cashDebtData}
        onRefresh={this.searchCollectors}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="CashDebtByCollectorsGrid"
      >
        <GridColumn
          field="CollectorCode"
          title="page.cashDebtByCollectorsGrid.col-userNumber"
          width="180"
          filter="numeric"
        />
        <GridColumn field="CollectorName" title="page.cashDebtByCollectorsGrid.col-name" width="240" />
        <GridColumn
          field="Date"
          title="page.cashDebtByCollectorsGrid.col-date"
          width="160"
          filter="date"
          format="dd.MM.yyyy"
        />
        <GridColumn field="BeginBalans" title="grids.cashDept.column-startingBalance" width="190" />
        <GridColumn field="Collection" title="grids.cashDept.column-CollectionAmount" width="170" />
        <GridColumn field="Enrollment" title="grids.cashDept.column-AmountCredited" width="280" />
        <GridColumn field="Lack" title="grids.cashDept.column-write-off-shortfall" width="170" />
        <GridColumn field="Adjustment" title="grids.cashDept.column-Adjustments" width="170" />
        <GridColumn field="EndBalans" title="grids.cashDept.column-BalanceAtTheEnd" width="170" />
      </Grid>
    );
  }
}

export default withTranslate(CashDebtByCollectorsGrid);
