import React, { PureComponent } from 'react';
import { Button, Card, Select } from 'antd';

import CustomInput from '../../../components/Input';
import { withTranslate } from '../../../contexts/localContext';
import { LABEL_TRANSLATIONS, RISC_LEVEL_FIELDS, STATIC_SELECT_LIST_BY_FIELD_NAME } from './constants';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import QuestionnaireService from './Questionnaire.service';

import './Questionnaire.styled.scss';

const { Option } = Select;

class Questionnaire extends PureComponent {
  currentArray = findArray('clientCabinetFinmon');

  renderInput = ({ name, type, props: fieldProps, isDisabled }) => {
    const { formState, onChangeFormState, formErrors, riskLevelsList, translate, isEditingEnabled, setError } =
      this.props;

    const selectListByFieldName = {
      ...STATIC_SELECT_LIST_BY_FIELD_NAME,
      ...RISC_LEVEL_FIELDS.reduce((acc, fieldName) => ({ ...acc, [fieldName]: riskLevelsList }), {}),
    };

    if (type === 'select') {
      return (
        <div className="Questionnaire-input">
          <Select
            onChange={(value) => {
              onChangeFormState(name, value);
            }}
            value={formState[name]}
            disabled={isDisabled || !isEditingEnabled}
            allowClear
          >
            {selectListByFieldName[name].map(({ title, value }) => (
              <Option key={value} value={value}>
                {title}
              </Option>
            ))}
          </Select>
        </div>
      );
    }

    return (
      <div className="Questionnaire-input">
        <CustomInput
          formState={formState}
          onChangeFormState={onChangeFormState}
          formErrors={formErrors}
          name={name}
          type={type}
          disabled={!isEditingEnabled || isDisabled}
          validators={QuestionnaireService.getValidators(translate)}
          setError={setError}
          {...fieldProps}
        />
      </div>
    );
  };

  renderFieldRow = (props) => {
    const { translate } = this.props;
    return (
      <div className="Questionnaire-fieldRow">
        <div className="Questionnaire-label">{translate(LABEL_TRANSLATIONS[props.name])}</div>
        {this.renderInput(props)}
      </div>
    );
  };

  render() {
    const { translate, downloadUserQuestionnaire, isEditingEnabled, onActionsSubmit, formErrors } = this.props;

    return (
      <>
        <div className="Questionnaire-actions">
          <div>
            <Button type="primary" onClick={downloadUserQuestionnaire}>
              {translate('page.clientCabinet.downloadUserQuestionnaire')}
            </Button>
          </div>

          {checkElement('clientCabinetFinmon-questionnaireProfileEdit', this.currentArray) && (
            <div>
              <Button
                type="primary"
                onClick={onActionsSubmit}
                disabled={isEditingEnabled && Object.values(formErrors).some(Boolean)}
              >
                {translate(isEditingEnabled ? 'core.buttonTitles.save' : 'core.buttonTitles.edit')}
              </Button>
            </div>
          )}
        </div>

        <Card
          type="inner"
          title={translate('page.clientCabinet.userQuestionnaire')}
          style={{ marginTop: '20px' }}
          className="Questionnaire"
        >
          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'LastName' })} {this.renderFieldRow({ name: 'FirstName' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'MiddleName' })}
            {this.renderFieldRow({ name: 'Birthday', type: 'datePicker', props: { format: 'dd.MM.yyyy' } })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'Document' })}
            {this.renderFieldRow({ name: 'TaxNumber', isDisabled: true })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'Phone' })}
            {this.renderFieldRow({ name: 'Citizenship' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'Residence', type: 'textArea' })}
            {this.renderFieldRow({ name: 'BirthPlace', type: 'textArea' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'RecordNumber' })}
            {this.renderFieldRow({ name: 'Employer' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'Position' })}
            {this.renderFieldRow({ name: 'Employment', type: 'textArea' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'TotalMonthlyIncome' })}
            {this.renderFieldRow({ name: 'IncomeSources', type: 'textArea' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'EasypayServices', type: 'textArea' })}
            {this.renderFieldRow({ name: 'MonthlyPaymentsAmount' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'MonthlyPaymentLimit', type: 'select', isDisabled: true })}
            {this.renderFieldRow({ name: 'BanksAccountsTypes' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'BanksDepositsAmount' })}
            {this.renderFieldRow({ name: 'Securities' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'Email' })}
            {this.renderFieldRow({
              name: 'BusinessRelationsStartDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
            })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'RiskLevel', type: 'select' })}
            {this.renderFieldRow({
              name: 'RiskLevelDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
            })}
          </div>

          <Card
            type="inner"
            title={translate('page.clientCabinet.reassessmentRiskLevel')}
            style={{ marginTop: '20px' }}
            className="Questionnaire Questionnaire-subCard"
          >
            <div className="Questionnaire-row">
              {this.renderFieldRow({ name: 'ReassessmentRiskLevel1', type: 'select' })}
              {this.renderFieldRow({ name: 'ReassessmentRiskLevel2', type: 'select' })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({ name: 'ReassessmentRiskLevel3', type: 'select' })}
            </div>
          </Card>

          <Card
            type="inner"
            title={translate('page.clientCabinet.reassessmentRiskLevelDate')}
            style={{ marginTop: '20px' }}
            className="Questionnaire Questionnaire-subCard"
          >
            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'ReassessmentRiskLevelDate1',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
              {this.renderFieldRow({
                name: 'ReassessmentRiskLevelDate2',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'ReassessmentRiskLevelDate3',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
            </div>
          </Card>

          <div className="Questionnaire-row">
            {this.renderFieldRow({
              name: 'CreateDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
              isDisabled: true,
            })}
            {this.renderFieldRow({
              name: 'VerificationDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
              isDisabled: true,
            })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'VerificationSource', type: 'select', isDisabled: true })}
          </div>

          <Card
            type="inner"
            title={translate('page.clientCabinet.clientInfo')}
            style={{ marginTop: '20px' }}
            className="Questionnaire Questionnaire-subCard"
          >
            <div className="Questionnaire-row">
              {this.renderFieldRow({ name: 'ActualizationInfo', type: 'textArea' })}
              {this.renderFieldRow({ name: 'PersonCheckingInfo', type: 'textArea' })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({ name: 'PersonFinMonitoringInfo', type: 'textArea' })}
            </div>
          </Card>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'PostDate', type: 'datePicker', props: { format: 'dd.MM.yyyy' } })}
            {this.renderFieldRow({ name: 'PersonRepresentativeInfo', type: 'textArea' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'IndividualEntrepreneurData', type: 'textArea' })}
            {this.renderFieldRow({ name: 'BusinessActivityTypes', type: 'textArea' })}
          </div>

          <div className="Questionnaire-row">
            {this.renderFieldRow({ name: 'IndependentProfessionalActivityTypes', type: 'textArea' })}
          </div>

          <Card
            type="inner"
            title={translate('page.clientCabinet.RERStatus')}
            style={{ marginTop: '20px' }}
            className="Questionnaire Questionnaire-subCard"
          >
            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'Pep',
                type: 'checkbox',
              })}
              {this.renderFieldRow({
                name: 'PepCategory',
              })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'PepPeriod',
              })}
              {this.renderFieldRow({
                name: 'PepOriginPerson',
              })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'PepPosition',
                type: 'textArea',
              })}
              {this.renderFieldRow({
                name: 'PepDiscoveryDate',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
            </div>

            <div className="Questionnaire-row">
              {this.renderFieldRow({
                name: 'PepBusinessRelationsStartDate',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
              {this.renderFieldRow({
                name: 'PepActivitiesExpiredDate',
                type: 'datePicker',
                props: { format: 'dd.MM.yyyy' },
              })}
            </div>
          </Card>

          <div className="Questionnaire-row">
            {this.renderFieldRow({
              name: 'BusinessRelationsEndDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
            })}
            {this.renderFieldRow({
              name: 'AssetsFreezingDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
            })}
          </div>

          <div className="Questionnaire-row Questionnaire-rowWithGridSelect">
            {this.renderFieldRow({
              name: 'AssetsUnFreezingDate',
              type: 'datePicker',
              props: { format: 'dd.MM.yyyy' },
            })}
            {this.renderFieldRow({
              name: 'PostUser',
              isDisabled: true,
            })}
          </div>
        </Card>
      </>
    );
  }
}

export default withTranslate(Questionnaire);
