import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import GoogleMapComponent from './googleMap';
import { DEFAULT_ADDRESS_STATE } from './constants';

import './modalGoogleMap.scss';

class ModalGoogleMap extends PureComponent {
  constructor(props) {
    super(props);
    const { currentGeopoint } = props;
    this.state = {
      currentAddressState: DEFAULT_ADDRESS_STATE,
      currentGeopoint,
    };
  }

  onGeopointChange = (currentGeopoint) => {
    if (currentGeopoint && currentGeopoint !== '') {
      this.setState({ currentGeopoint });
    }
  };

  onGoogleMapChangeAddress = (currentAddressState) => {
    this.setState({ currentAddressState });
  };

  onOkHandler = () => {
    const {
      currentAddressState: { addressArea, ...restAddressState },
      currentGeopoint,
    } = this.state;

    const { onChange, onChangeGeopoint, closeModal } = this.props;

    onChange(restAddressState);
    onChangeGeopoint(currentGeopoint);
    closeModal();
  };

  getCityName = () => {
    const { cities, cityId } = this.props;

    if (!cities) {
      return 'Київ';
    }

    const currentCity = cities.filter((el) => el.CityId === cityId);

    return (currentCity && currentCity[0] && currentCity[0].CityName) || 'Київ';
  };

  render() {
    const { visible, closeModal, translate, currentGeopoint } = this.props;
    return (
      <>
        <Modal
          title={translate('page.terminalSettings.modal-map-title')}
          visible={visible}
          centered
          onOk={this.changeProfile}
          onCancel={closeModal}
          className="modal-system-encashment modal-google-map"
          // zIndex={'1053'}
          footer={[
            <Button key="back" onClick={closeModal}>
              {translate('page.screenControlBusinessServices.modal-set-btn-back-btn')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.onOkHandler}>
              {translate('page.screenControlBusinessServices.modal-set-btn-submit-btn')}
            </Button>,
          ]}
        >
          <GoogleMapComponent
            onChange={this.onGoogleMapChangeAddress}
            cityName={this.getCityName()}
            currentGeopoint={currentGeopoint}
            onChangeGeopoint={this.onGeopointChange}
          />
        </Modal>
      </>
    );
  }
}

export default withTranslate(ModalGoogleMap);
