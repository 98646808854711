import { CORP_BOUNDS, DEFAULT_ADDRESS_STATE, ROUTE_ALIASES_LIST, STREET_BOUNDS } from './constants';

class GoogleMapService {
  parseAddress = ({ results }) => {
    if (!results.length || !results[0].address_components || !results[0].address_components.length) {
      return DEFAULT_ADDRESS_STATE;
    }

    const { streetType, streetName } = this.getStreetInfo(results);

    return {
      addressArea: this.getAddressByType(results, 'locality'),
      addressStreetType: streetType,
      addressStreetName: streetName,
      addressBuilding: this.getAddressByType(results, 'street_number'),
      addressBuildingPart: this.getAddressByType(results, 'subpremise'),
      addressCorp: this.getAddressCorp(results),
      addressApartment: this.getAddressByType(results, 'room'),
    };
  };

  getAddressCorp = (results) => {
    const corpValue = this.getAddressByType(results, 'premise');

    return corpValue && CORP_BOUNDS.reduce((acc, bound) => acc.replace(bound, ''), corpValue);
  };

  getStreetInfo = ([{ address_components }]) => {
    const route = address_components.find(({ types }) => STREET_BOUNDS.find((bound) => types.includes(bound)));

    if (!route) {
      return { streetType: '', streetName: '' };
    }

    const routeType =
      ROUTE_ALIASES_LIST.find(({ slugList }) => slugList.find((slug) => route.long_name.includes(slug))) ||
      ROUTE_ALIASES_LIST[0];

    const streetName = routeType.slugList.reduce(
      (acc, slug) =>
        acc.replace(` ${slug} `, '').replace(` ${slug}`, '').replace(`${slug} `, '').replace(`${slug}`, ''),
      route.long_name,
    );

    return {
      streetType: routeType.id,
      streetName: streetName,
    };
  };

  getAddressByType = ([{ address_components }], type) => {
    const addressByType = address_components.find(({ types }) => types.includes(type));

    return addressByType ? addressByType.long_name : '';
  };
}

export default new GoogleMapService();
