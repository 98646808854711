import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import FormStateContainer from '../FormStateContainer';
import { withTranslate } from '../../contexts/localContext';
import ExtendedAddressForm from './ExtendedAddressForm';
import { REQUIRED_FIELD } from './contants';

import './ExtendedAddressForm.styled.scss';

class ExtendedAddressModal extends PureComponent {
  render() {
    const { translate, extendedAddress, closeDialog, formState, updateExtendedAddress, ...restProps } = this.props;

    const isSaveDisabled = REQUIRED_FIELD.some((filedName) => !formState[filedName]);

    return (
      <Modal
        title={translate('page.terminalSettings.address-confirm-title')}
        visible={extendedAddress}
        onCancel={closeDialog}
        className="ExtendedAddressModal"
        footer={[
          <Button
            type="primary"
            onClick={() => {
              updateExtendedAddress(formState);
            }}
            disabled={isSaveDisabled}
          >
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button onClick={closeDialog}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <ExtendedAddressForm formState={formState} {...restProps} />
      </Modal>
    );
  }
}

class ExtendedAddressModalWrapper extends PureComponent {
  render() {
    const { translate, extendedAddress, closeDialog, updateExtendedAddress, routes } = this.props;

    return (
      <FormStateContainer initialValues={extendedAddress}>
        <ExtendedAddressModal
          translate={translate}
          extendedAddress={extendedAddress}
          closeDialog={closeDialog}
          updateExtendedAddress={updateExtendedAddress}
          routes={routes}
        />
      </FormStateContainer>
    );
  }
}

export default withTranslate(ExtendedAddressModalWrapper);
